import React, { Fragment } from 'react';
import ErrorMsgBar from './ErrorMsgBar';

export default class SearchArea extends React.Component{
  render(){
    const { children, popup } = this.props

    const area_style = {
      backgroundColor:"#f9f9f3", 
      padding:"16px",
    }

    return(
      <Fragment>
        { !popup && <ErrorMsgBar currentPage = { 1 } /> }
        <div style = { area_style }>
          { children }
        </div>
      </Fragment>
    )
  }
}