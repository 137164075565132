import React, { Fragment, Children } from 'react';

export default class EDIBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    }
  }
  
  render(){
    const { mode, checkPage,color,label,children } = this.props
    const { hover } = this.state

    const btn_style = {
      margin:'5px 16px 16px 5px',
      height: 35,
      padding:"0px 24px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 5,
      // boxShadow:" 2px 2px 2px #aaa",
      cursor: "pointer",
      border:"3px solid " + color,
      color: 
        hover ? color :
        "#fff",
      backgroundColor: 
        hover ? "#fff" :
        color, 
      transition:"0.3s",
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { btn_style }
        onClick      = { this.props.onClick } 
        onKeyDown    = { this.props.onKeyDown }
        onMouseOver  = { () => this.setState({ hover : true  }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
        onFocus      = { () => this.setState({ hover : true }) }
        onBlur       = { () => this.setState({ hover : false }) }
      >
        { label }
        { children }
      </button>
    )
  }
}
