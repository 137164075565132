import React from 'react';
import Low from '../component/Low';

const label_style = {   // <label> テキストのラベル
  fontSize:14,
  marginRight:"1px",
  paddingLeft:"1px",
}

export default class CircleIcon extends React.Component{
  render(){
      const circleStyle = {
        width:25,
        height:25,
        background:this.props.color,
        borderRadius:'50%',
        margin: '5px 0px 5px 5px',
      }
      return(
          <Low middle>
            <div style={circleStyle}/>
            <label style={label_style}>{this.props.label}</label>
          </Low>
      )
  }
}