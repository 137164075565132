import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { history } from './createStore';
import COMMON from '../pgm/entry/LoginReducer';

export const makeAllReducer = (asyncReducers) => combineReducers({
  router: connectRouter(history),
  ...asyncReducers
});

export const injectReducer = (store,{key,reducer}) => {
  //Login以外の機能のReducerはPGMの名前で切り替える
  //if(key != 'Login')store.asyncReducers['PGM']=reducer

  //機能ごとでStoreを再分配
  if (Object.hasOwnProperty.call(store.asyncReducers, key)) return;
  store.asyncReducers[key] = reducer;
  store.replaceReducer(makeAllReducer(store.asyncReducers));
}

export const createReducer = (initialState, ACTION_HANDLES) => (
 (state = initialState, action) => {
   const handler = ACTION_HANDLES[action.type];
   return handler ? handler(state, action) : state;
 }
);

export const clearReducer = (state, action) =>{
  if(action === 'SYSTEMLOGOUT'){
    state = undefined;
  }
  return makeAllReducer({['COMMON']:COMMON})
};