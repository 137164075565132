import TextNum from '../component/TextNumber';
import Textarea from '../component/TextArea';
import Text from "../component/Text";
import TextModal from "../component/TextModal";
import Select from '../component/Select';
import Btn from '../component/Button';
import Link from '../component/Link';
import Icon from '../component/Icon';
import RadioList from '../component/RadioList';
import CheckBox from '../component/CheckBox';
import Calendar from '../component/Calendar';
import Form from '../component/Form'
import {confirm} from '../component/Confirm';
import { openModal, closeModal } from '../component/Modal'
// import Loading from '../component/Loading';
import Blank from '../component/Blank';
import InputTable from '../component/InputTable';// 入力テーブル
import ListTable from '../component/ListTable';// 一覧テーブル
import EDITable from "./EDITable"; 
import Low from '../component/Low';
import Col from '../component/Col';
import HideBtn from './HideBtn';
import ModeBtn from './ModeBtn';
import Hideable from './EDIHideable';
import SearchArea from './SearchArea';
import InputArea from './InputArea';
import InputBtnArea from './InputBtnArea';
import Title from './Title';
import Tilde from './Tilde';
import BackBtn from './BackBtn';
import ConfBtn from "./ConfBtn";
import DeleteBtn from "./DeleteBtn";
import SubmitBtn from "./SubmitBtn";
import EDIHeader from "./EDIHeader";
import LogoutBtn from "./LogoutBtn";
import EDIBtn from './EDIBtn';
import CircleIcon from './CircleIcon';
import EDILabel from './EDILabel';
import CalendarComponent from './CalendarComponent';
import Loading from './EDILoading';

const TABLECOLOR1    = "#556DAE";
const TABLECOLOR2    = "#f9f9ff";
const TABLECOLORF    = "#fff";
const SEARCHBTNCOLOR = "#556DAE" ;

export {
    //標準Component
    Text
  ,TextNum
  ,TextModal
  ,Textarea
  ,Calendar
  ,Form
  ,confirm
  ,openModal
  ,closeModal
  ,Select
  ,CheckBox
  ,RadioList
  ,Btn
  ,Link
  ,Icon
  ,Loading
  ,Low
  ,Col
  ,Blank
  ,InputTable
  ,ListTable
  ,EDITable
　　//EDIComponent
    ,HideBtn
    ,ModeBtn
    ,Hideable
    ,SearchArea
    ,EDIBtn
    ,InputArea
    ,InputBtnArea
    ,Title
    ,BackBtn
    ,ConfBtn
    ,DeleteBtn
    ,SubmitBtn
    ,EDIHeader
    ,LogoutBtn
    ,Tilde
    ,CircleIcon
    ,EDILabel
    ,CalendarComponent
  // EDI用const
  ,TABLECOLOR1
  ,TABLECOLOR2
  ,TABLECOLORF
  ,SEARCHBTNCOLOR

}