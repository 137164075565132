import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import Entry from './pgm/entry/Entry';
import "./css/EDIStyle.css";
import "./css/reset.css";
import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux';
import createStore from './comRedux/createStore';

export const store = window.store = createStore();
ReactDOM.render(
         <Provider store={store}>
            <Entry />
        </Provider>,document.getElementById('root'));

serviceWorker.unregister();
