import React, { Fragment } from 'react';

export default class EDILabel extends React.Component{
    render(){
      const { size, value, label } = this.props
      let boxWidth = 156;
      if( size ){
        switch( size ){
          case "ss" : case "SS" :  boxWidth = 70;   break
          case "s"  : case "S"  :  boxWidth = 113;  break
          case "m"  : case "M"  :  boxWidth = 156;  break
          case "l"  : case "L"  :  boxWidth = 328;  break
          case "ll" : case "LL" :  boxWidth = 672; break    
          default   : boxWidth = size; 
        } 
      }

      //////////// スタイル記述欄ここから ////////////
      const text_wrap = {
        justifyContent:'center',
        alignItems:'center',
        display:'-webkit-flex',
        marginBottom:16,
      }

      const text = {
        fontSize:14,
        borderRadius:5,
        width: boxWidth,
        backgroundColor :"#fee",
        border : "1px solid #aaa",
        pointerEvents:"none",
        boxSizing:"border-box",
        transition:"0.3s",
        marginRight:16,
      }

      return (
        <Fragment>
          { value &&
            <div style={text_wrap}>
              <div>
                <label style={text}>
                  {label? label+":"+ value : value}
                </label>
              </div>
              </div>
            }
        </Fragment>
      );
    }
}