import React, { Fragment } from 'react';
import {ToHalf,ToFull} from '../util/commonInput';

export default class Text extends React.Component  {
      constructor(props) {
        super(props);
        this.state = {
            value: props.value || '',
        };
      }
      // _____________________________________________________________________

      componentDidMount(){
        switch( this.props.type ){
          case "password":
            this.forward_ref.type = "password";
          break;
          case "number":
            this.forward_ref.type = "tel";
          break
          default:
            this.forward_ref.type = "text";
          break;
        }        

        if( this.props.autoFocus && !(this.props.disabled || this.props.readOnly)){ this.forward_ref.focus() }    // autoFocus機能
      }
      // ______________________________________________________________________

      shouldComponentUpdate(nextProps){
        if(this.props.value !== nextProps.value){
          this.setState({ value: nextProps.value })
        }
        return true
      }
      // ______________________________________________________________________


      handleOnBlur = e =>{
        if(this.props.email && e.target.value != ''){
          let regExp = /\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*/; 
          if(!regExp.test(e.target.value)){
            e.target.focus();
            alert(' please input correct email!');
          }
        }
        // this.props.onChange && this.props.onChange(this.props.name,e.target.value);
        this.setState({ focusStyle : null })
      }
      // ______________________________________________________________________________________________________

      handleOnChange = e => {
        if(this.props.imeMode && this.props.imeMode.toUpperCase() === 'HALF')e.target.value = ToHalf(e.target.value);
        if(this.props.imeMode && this.props.imeMode.toUpperCase() === 'FULL')e.target.value = ToFull(e.target.value);
        ;
        
        // this.updateValid(e.target.value);
        //this.props.onChange && this.props.onChange(e.target.name,e.target.value);
        let replaceValue

        // typeによって文字列変換をするためのswitch文です
        switch( this.props.type ){
          case "number" : 
            replaceValue = e.target.value.replace(/[^\d]/g,'');
          break;

          default : 
            replaceValue = e.target.value
          break;
        }
        this.props.onChange && this.props.onChange(this.props.name,e.target.value);
        this.setState({ value: replaceValue })
      }
      // _______________________________________________________________________________________________________
      render() {
        const { size, disabled,readOnly, must, label, style, type } = this.props
        const { hoverStyle } = this.state
        
        let boxWidth = 156;
        if( size ){
          switch( size ){
            case "ss" : case "SS" :  boxWidth = 70;   break
            case "s"  : case "S"  :  boxWidth = 113;  break
            case "m"  : case "M"  :  boxWidth = 156;  break
            case "l"  : case "L"  :  boxWidth = 328;  break
            case "ll" : case "LL" :  boxWidth = 672; break    
            default   : boxWidth = size; 
          } 
        }

        //////////// スタイル記述欄ここから ////////////
        const text_wrap = {
          display: 
            style ? null:
            "inline-flex",
          flexDirection:"column",
        }

        const text = {
          fontSize:16,
          borderRadius:5,
          height: 30,
          paddingLeft: 5,
          paddingRight:
            type === "number" ? 5 :
            null,
          width: boxWidth,
          backgroundColor : 
            disabled? "transparent" : 
            must ? this.state.value ? null :"#fee": 
            null,
          border : "1px solid #aaa",
          pointerEvents: disabled||readOnly? "none" : null,
          boxSizing:"border-box",
          transition:"0.3s",
          marginRight:16,
          marginBottom:16,
          textAlign:
            type === "number" ? 'right' :
            null
        }

        const label_style = {
          fontSize:14,
          marginRight:"1px",
          paddingLeft:"1px",
        }

        const must_style = {
          fontSize:14,
          fontWeight:"bolder",
          color:"#d00",
        }
        //////////// スタイル記述欄ここまで ////////////

        return (
          <Fragment>
            <div style={text_wrap}>
              { label &&
              <div style={{ paddingBottom:"4px"}}>
                <label style={label_style}>
                  {label}
                </label>
                { label && must && !disabled &&
                <span style={ must_style } >＊</span>
                }
              </div>
              }
              
              {/* {(!this.makeCheck(this.props.must)  && !this.state.value && this.props.doCheck === true )? 
              //整合性アラーム
                <ValidationInputs msg={(<span>{this.error}</span>)}/>
              :"" } */}

              <input 
                autoComplete = "off"
                style        = { Object.assign({},text, hoverStyle, this.state.focusStyle, style ) }
                name         = { this.props.name }
                maxLength    = { this.props.maxLength }
                value        = { this.state.value }
                onChange     = { this.handleOnChange }
                onBlur       = { this.handleOnBlur }
                className    = { this.props.className }
                readOnly     = { readOnly }
                disabled     = { disabled }
                ref          = { (e) => this.forward_ref = e }
                onFocus      = { ()  => this.setState({ focusStyle : {backgroundColor: "#eef" } }) }
                onMouseOver  = { ()  => this.setState({ hoverStyle : {boxShadow: "0px 0px 3px 0px #00f"} }) }
                onMouseLeave = { ()  => this.setState({ hoverStyle : null }) }
              />
            </div>
          </Fragment>
        )
    }

}

