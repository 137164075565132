import React, { Fragment } from 'react';

export default class SubmitBtn extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    }
  }
  
  render(){
    const { mode, checkPage,label,children,disabled } = this.props
    const { hover } = this.state

    if( mode == 3 || !checkPage ){ return null }
    const btn_style = {
      marginRight:16,
      height: 35,
      padding:"0px 24px",
      fontWeight: "bold",
      fontSize: 14,
      borderRadius: 5,
      cursor: "pointer",
      border:"3px solid #d56c08",
      color: 
        hover ? "#d56c08" :
        "#fff",
      backgroundColor: 
        hover ? "#fff" :
        "#d56c08", 
      transition:"0.3s",
    }
    // ______________________________________________________________________________
    
    return(
      <button 
        style        = { btn_style }
        onClick      = { this.props.onClick } 
        onKeyDown    = { this.props.onKeyDown }
        onMouseOver  = { () => this.setState({ hover : true  }) }
        onMouseLeave = { () => this.setState({ hover : false }) }
        onFocus      = { () => this.setState({ hover : true }) }
        onBlur       = { () => this.setState({ hover : false }) }
        disabled     = { disabled }
      >
        { label }
      </button>
    )
  }
}
