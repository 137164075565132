import React, { Fragment } from 'react';
import ValidationInputs from './ValidationInputs';

export default class TextArea extends React.PureComponent{
        constructor(props) {
                super(props);
                this.state = {
                    value:props.value || '',
                    isValid:true,
                };
                this.error = "error";
              }
        
        componentDidMount(){
            this.updateValid(this.state.value);
        }
        // ______________________________________________________________________________________

        handleOnChange = e => {
            this.setState({value:e.target.value});
            this.updateValid(e.target.value);
            //this.props.onChange && this.props.onChange(e);
        }
        // ______________________________________________________________________________________
   
        handleOnBlur = e =>{
            this.props.onChange && this.props.onChange( this.props.name, e.target.value );
            this.setState({ focusStyle : null })
        }
        // ______________________________________________________________________________________
        updateValid = (value) =>{
            if(this.props.must && (value == '' || typeof(value)=='undefined')){
                this.setState({isValid:false});
            }else{
                this.setState({isValid:true});
            }
        }
        render(){

            let boxWidth = 156;
            if( this.props.size ){
              switch( this.props.size ){
                case "ss" : case "SS" :  boxWidth = 70;   break
                case "s"  : case "S"  :  boxWidth = 113;  break
                case "m"  : case "M"  :  boxWidth = 156;  break
                case "l"  : case "L"  :  boxWidth = 328;  break
                case "ll" : case "LL" :  boxWidth = 672; break    
                default   : boxWidth = this.props.size; 
              } 
            }
    
            //////////// スタイル記述欄ここから ////////////
            const { h,w } = this.props
            const text_wrap = {
              position:"relative",
              display:"inline-flex",
              flexDirection:"column",
            }
    
            const textarea = {
              fontSize:16,
              borderRadius:5,
              height: 
                h ? h : 
                60,
              paddingLeft: 5,
              width: 
                w ? w :
                boxWidth,
              backgroundColor : 
                this.props.disabled? "transparent" : 
                this.props.must ? this.state.value ? null :"#fee": 
                null,
              border : "1px solid #aaa",
              pointerEvents: this.props.disabled? "none" : null,
              boxSizing:"border-box",
              transition:"background-color 0.3s",
              marginRight:16,
              marginBottom:16,
              // maxWidth : "100%",
            }
    
            const label_style = {
              fontSize:14,
              marginRight:"1px",
              paddingLeft:"1px",
            }
    
            const must_style = {
              fontSize:14,
              fontWeigh:"bolder",
              color:"#d00",
            }
            //////////// スタイル記述欄ここまで ////////////
            return(          
            <Fragment>
                <div style={text_wrap}>
    
                  <div style={ this.props.label && { paddingBottom:"4px"} }>
                  { this.props.label &&
                    <label style={label_style}>
                      {this.props.label}
                    </label>
                  }
                  { this.props.label && this.props.must &&
                    <span style={ must_style } >＊</span>
                  }
                  </div>
                  
                  {/* {(!this.makeCheck(this.props.must)  && !this.state.value && this.props.doCheck === true )? 
                  //整合性アラーム
                    <ValidationInputs msg={(<span>{this.error}</span>)}/>
                  :"" } */}
                  <textarea 
                    style        = { Object.assign({},textarea, this.state.hoverStyle, this.state.focusStyle, this.props.style ) }
                    name         = { this.props.name }
                    className    = { this.props.className}
                    maxLength    = { this.props.maxLength}
                    readOnly     = { this.props.readOnly}
                    rows         = { this.props.rows}
                    cols         = { this.props.cols}
                    value        = { this.state.value}
                    onChange     = { this.handleOnChange}
                    ref          = { this.props.inputRef}
                    isvalid      = { this.state.isValid.toString()}
                    must         = { this.props.must && this.props.must.toString()}
                    onBlur       = { this.handleOnBlur }
                    onFocus      = { ()  => this.setState({ focusStyle : {backgroundColor: "#eef" } }) }
                    onMouseOver  = { ()  => this.setState({ hoverStyle : {boxShadow: "0px 0px 3px 0px #00f"} }) }
                    onMouseLeave = { ()  => this.setState({ hoverStyle : null }) }
                  />
                </div>
              </Fragment>
            )   
        }    
}
