import React from 'react';
import { connect } from 'react-redux';
import Centering from '../../component/Centering';
import Col from '../../component/Col';
import Text from '../../component/Text';
import Btn from '../../component/Button';
import  '../../css/kondostyle.css';
import Low from '../../component/Low';
import {login,loginEdit,error,logOut} from '../../comRedux/Actions';
import {axiosPost,server} from '../../comRedux/axiosPost';

class WQC_Index extends React.Component{

  constructor (props) {
    super(props);
  } 
  componentDidMount() {
    window.addEventListener('keypress', this.handleOnEnter, true);
  }
  componentWillUnmount() {
    // if (!!this.body) {
    window.removeEventListener('keypress', this.handleOnEnter, true);
    // }
  }
  handleOnEnter = e => {
    if (e && e.keyCode === 13) {
      this.handleOnClick(e);
    }
  }

  handleOnClick = (e) =>{
    var returnJson = {
      userCode:this.props.userCode,
      password:this.props.password,
   };
    e.preventDefault();
    console.log(returnJson)
    axiosPost.post(server+'/login/login',returnJson)
    .then((res) => {
      let member = res.data;
      if(member){
        if (member.loginSuccessed) {
          this.props.dispatch(login(member));
        }else{
          this.props.dispatch(error('ログインできません'));
        }
      }else{
        this.props.dispatch(error('ログインできません'));
      }
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
  }

  // テキストボックスの内容が変更された時にstateを更新
  handleOnChangeValue = (name,value) => {
    this.props.dispatch(loginEdit({[name]:value}));
  }

  render(){
    const err_style = {
      backgroundColor:"#d00",
      color:"#fff",
      padding:"8px",
      marginBottom:16,
      display :
        (!this.props.errMsg) ? "none" :
        null, 
    };
    return(
      <Centering className="wqc_login_outer" >
      <Col w >
        <Low center className="wqc_login_title">EDI-System</Low>
        <Low center>
          <Col className="wqc_login_inner">
            <div style={err_style}>{this.props.errMsg}</div> 
            <Text label="ユーザー名" name="userCode" must="true" value={this.props.userCode} onChange={(name,value)=>{this.handleOnChangeValue(name,value)}} style={{width:"100%",margin:null,}} className="login_text"  autoFocus="true"/>
            <Text label="パスワード" name="password" type="password" must="true" value={this.props.password} onChange={(name,value)=>{this.handleOnChangeValue(name,value)}} style={{width:"100%",margin:null,}} className="login_text" />
            <Btn  className="login_btn" onClick = {this.handleOnClick} > ログイン </Btn>
            <Low center><p className="login_sentens">©skysystem</p></Low>    
          </Col>
        </Low>
      </Col>
    </Centering>
    );
  }
}

const mapStateToProps = (state,ownPropS) =>{
  return {
    ...state.COMMON.Login,
  }
}

export default connect(mapStateToProps)(WQC_Index);