import React, { Fragment } from 'react';

export default class EDIHeader extends React.Component{
  render(){
    const header_style= {
      display:"flex",
      zIndex:500, 
      position:"fixed",
      width:"100%", 
      height:"50px", 
      top:0, 
      paddingLeft:16,
      justifyContent:"space-between", 
      backgroundColor:"#747",
      alignSelf:"center",
    }

    const title_style = {
      // margin: 8,
      fontFamily: "Georgia, 'Times New Roman', Times, serif",
      fontSize: 30,
      fontWeight:"bolder",
      color:"#efe",
      display:"flex", 
      alignSelf:"center",
    }

    const parts_style = {
      display:"flex",
      alignSelf:"center",
    }

    return(
      <div style={ header_style }>
        <div style={ title_style }>
          {this.props.title}
        </div>
        <div style={ parts_style } >
          {this.props.children}
        </div>
      </div>
    )
  }
}