 import request from 'superagent';
 import {axiosPost,server} from '../comRedux/axiosPost';
 import axios from 'axios';

 
 /*
   1 Level JSON作成
   returnJson : {
        NAME :VALUE
   }
 */
    function toJson(props) {
        var returnJson = {};
        for(let v in props){
        //項目がNullじゃない　且つ　ValueはUndefinedじゃない時、継続
            if(props[v] && typeof(props[v]['value']) !== 'undefined'){
                returnJson[v] = props[v].value;
            }
        }
        return returnJson;
    }

    function toJsonState(props) {
        var returnJson = {};
        for(let v in props){
            returnJson[v] = props[v].value;
        }
        return returnJson;
        }
    
    function toJsonList(forms,detailList) {
        var formData = forms.elements;
        var returnJson = {};
        var formDataStr;
        for (var idx = 0; idx < formData.length; idx++) {
            if (formData[idx].name!='') {
                returnJson[formData[idx].name] = formData[idx].value;
                formDataStr+= formData[idx].name + " : " + formData[idx].value + "\n";
            }
        }
        returnJson['detailFormBean'] = detailList;
        //alert(formDataStr);
        return returnJson;
    }

function checkDate(y,m,d){
    let dt = new Date();
    dt.setYear(y);dt.setMonth(m-1);dt.setDate(d);
    return (y == dt.getFullYear() && (m-1) == dt.getMonth() && parseInt(d) == dt.getDate()); 
};
    
function matchesSelector(element, selector){
                  if(element.matches){
                      return element.matches(selector);
                  } else if(element.matchesSelector){
                      return element.matchesSelector(selector);
                  } else if(element.webkitMatchesSelector){
                      return element.webkitMatchesSelector(selector);
                  } else if(element.msMatchesSelector){
                      return element.msMatchesSelector(selector);
                  } else if(element.mozMatchesSelector){
                      return element.mozMatchesSelector(selector);
                  } else if(element.oMatchesSelector){
                      return element.oMatchesSelector(selector);
                  }
              }
 
 function number_format(number, decimals, dec_point, thousands_sep){
/*
　　 * number：要格式化的数字
　　 * decimals：保留几位小数
　　 * dec_point：小数点符号
　　 * thousands_sep：千分位符号
　　 * */
    //number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    var n = !isFinite(+number) ? 0 : +number,
    prec = !isFinite(+decimals) ? 2 : Math.abs(decimals),
    sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
    dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
    s = '',
    toFixedFix = function(n, prec) {
        var k = Math.pow(10, prec);
        return '' + Math.round(n * k) / k;
    };
    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    if(!(typeof thousands_sep === 'undefined')){
        var re = /(-?\d+)(\d{3})/;
        while(re.test(s[0])) {
            s[0] = s[0].replace(re, "$1" + sep + "$2");
        }
    }
    if((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
};

function  ToHalf(str) { 
    var tmp = ""; 
    for(var i=0;i<str.length;i++){ 
        if (str.charCodeAt(i) === 12288){
            tmp += String.fromCharCode(str.charCodeAt(i)-12256);
            continue;
        }
        if(str.charCodeAt(i) > 65280 && str.charCodeAt(i) < 65375){ 
            tmp += String.fromCharCode(str.charCodeAt(i)-65248); 
        } 
        else{ 
            tmp += String.fromCharCode(str.charCodeAt(i)); 
        } 
    } 
    return tmp;
};

function ToFull(str){ 
    var tmp = "";
    for(var i=0;i<str.length;i++){
        if(!(str.charAt(i).match(/[\uff00-\uffff]/g) == null)){
            tmp = tmp + str.charAt(i);
            continue;
        }
        if(str.charCodeAt(i) === 32){
            tmp= tmp+ String.fromCharCode(12288);
            continue;
        } 
        if(str.charCodeAt(i)<127){ 
            tmp=tmp+String.fromCharCode(str.charCodeAt(i)+65248); 
        } 
    }
    return tmp; 
};

// MUSTがあるコンポーネントの必須チェック(検索画面)
function checkProps(props) {
    // console.log(props)
    for(let v in props) {
      if(!props[v])continue;//項目がNull時Continue
      const { value, must } = props[v];      // 省略宣言
      // console.log(v,value,must)
      if(!must) { continue; } // must が設定されていなければ ループをスキップ
      if(!value || value.length <= 0) { return false; }   //  エラーチェック　type : { value :[], must: true } 型に対応するため value.length <= 0 で対応しています
      
      //  type : { value :[], must: [] } 型のエラーチェック
      if(Array.isArray(value) && Array.isArray(must)) { 　// valueがArray型なら 
        for( let i in value ){        // 行ループ
                  
          let inValue = false;         // 値が入っているかチェックするための変数を宣言
          for(let j in value[i]) {   // 列ループ
            if(value[i][j]) {  // 値が入っていたら　
              inValue = true;   　// 値が入っていることを認識
              break;
            }
          }
  
          if(inValue){              // 行に値が入っていたら
            for(let j in value[i]) {   // 列ループ
              let result = must.indexOf(j)      // 配列の中身を検索してmust指定したものを検知
              if(result !== -1 && !value[i][j]) {  // そのセルがmustでかつ値がなければ
                return false; // エラー
              }   
            }
          }
        }
      }
    }
    return true;
  };

// MUSTがあるコンポーネントの必須チェック(登録画面)
// function checkInputProps(props) {
//     for(let v in props.inputValues){
//         if(props.inputValues[v].must == true &&
//             (props.inputValues[v].value == 'undefined' || props.inputValues[v].value == "")){
//                 return false;
//             }
//     }
//     return true;
// };
function loadInputJson(state,json,clearArr){
    //jsonを遍歴
    for(let v in json){
        //InputValuesに存在する項目のみセットする
          if(typeof(state.inputValues[v]) !== 'undefined'){
            //特別の項目は特別で対応
            if(Array.isArray(clearArr) && clearArr.indexOf(v) > -1){
                    state.inputValues[v].value = '';// クリア
                    continue;
            }
            //項目の値はValueに管理する場合
            if(state.inputValues[v].hasOwnProperty('value')){
              state.inputValues[v].value = json[v];
            }else{
            //項目の直下に値を管理する場合
              state.inputValues[v] = json[v];
            }
          }
      }
}

function mapDataKeyFromJson(state,json,dataKeys){
    if(isEmptyObject(json))return null;
    for(let i = 0;i< dataKeys.length;i++){
        let name = dataKeys[i];
        //項目の値はValueに管理する場合
        if(typeof(state.inputValues[name]) === 'undefined'){
            state.inputValues[name] = ''
        }else{
            if(state.inputValues[name].hasOwnProperty('value')){
                state.inputValues[name].value = json[name];
            }else{
            //項目の直下に値を管理する場合
                state.inputValues[name] = json[name];
            }
        }
    }
}

function mapDataKeyToPayload(payload,props,dataKeys){
    if(isEmptyObject(props))return null;
    for(let i = 0;i< dataKeys.length;i++){
        let name = dataKeys[i];
        if(typeof(props[name]) === 'undefined'){
            payload[name] ='';
        }else{
            if(props[name].hasOwnProperty('value')){
                //項目は定義していない　もしくは　項目の直下に値を管理する場合
                payload[name] = props[name].value;
            }else{
                //項目の値はValueに管理する場合
                payload[name] = props[name];
            }
        }
    }
}

//ObjectはNullかどうか
function isEmptyObject(obj){
    for(let key in obj){
        return false
    };  
    return true  
 }
    

async function getOptions(url){
    //オプションの初期値はブランクにする
    //let options = [{'value':'','label':''}];
    //axiosに変更するので、SuperAgentの非同期処理を取りやめ
    // var getJsonP  =  new Promise((resolve) =>{
    //     request.get('http://localhost:8080'+url)
    //     .accept('application/json')
    //     .end((err,res) => {
    //         if(err) {
    //             this.onError(err);
    //             console.log('JSON読み込みエラー')
    //         }
    //         resolve(res.body);
    //     })
    // });

    let options = [{'value':'','label':''}];
   return new Promise((resolve,reject) =>{
        axiosPost.get(server+url)
                    .then((res) => {
                        if(res.data != null){
                            resolve(res.data);
                        }else{
                            resolve(options);
                        }
                    }).catch(
                        err => {
                            reject(err)
                        }
                    );
    })
    // return options;
}

async function getMultiRequest(requestList){
    if(requestList.length > 0){
        // return new Promise((resolve,reject) =>{
          return  axios.all(requestList).then(
                axios.spread(function(res1,res2){
                    return [res1.data,res2.data]
                    }
                )
            ).catch( err => console.log(err))
        // })
    }
};

async function getJSON(url){
    let p = new Promise(
     (resolve,reject) =>{
       request.get(url).accept('application/json')
       .end((err,res) => {
           if(err) {
               console.log('JSON読み込みエラー')
               reject(err);
           }
             resolve(res.body);
       })
     }
    );

    let result = p.then( (res) => res );
    return result;
};

async function requiredCheck(state){
    let checkFn = new Promise(
        (resolve) =>{
        }
    );
}

async function getName(url,key,name){
    var res='';
    await axiosPost.get(server+url+'/?key='+key).then(rtn => {res = rtn.data[name]; });
    return res;
}

function getNextYM(ym){
   return getYM('next',ym);
}

function getPreYM(ym){
    return getYM('pre',ym);
}

function getYM(kbn,ym){
    if(typeof ym === 'undefined' || ym === '') return '';
    let y,m,sep;
    if(ym.indexOf('/') > 0){
        y = new Number(ym.split('/')[0]);
        m = new Number(ym.split('/')[1]);
        sep = '/';
    }else if(ym.indexOf('-') > 0){
        y = new Number(ym.split('-')[0]);
        m = new Number(ym.split('-')[1]);
        sep = '-';
    }
    if(kbn === 'next'){
        if(m < 12){
            m = m+1;
        }else{
            m = 1;
            y = y + 1;
        }
    }else if(kbn === 'pre'){
        if(m > 1){
            m = m-1;
        }else{
            m = 12;
            y = y - 1;
        }
    }
    return y +  sep + ("0" + m).slice(-2);
}

//stateの内部で、orgReducer領域のデータを戻す
//orgReducer：COMMON.COMM / COMM.Login
function selectImportantData(state,orgReducer){
    if(orgReducer === null || orgReducer === '' || typeof orgReducer === 'undefined')return {};
    const paths = orgReducer.split('.');
    let data = state;
    for(let i = 0;i<paths.length;i++){
        data = data[paths[i]];
    }
    return data
}

export{checkDate,matchesSelector,
    number_format,ToHalf,checkProps,
    ToFull,
    loadInputJson,
    mapDataKeyFromJson,//Reducer用
    mapDataKeyToPayload,//Searchlist&Input用
    toJsonList,
    toJson,
    toJsonState,
    getOptions,
    getJSON,
    isEmptyObject,
    getMultiRequest,
    getName,
    getNextYM,
    getPreYM,
    selectImportantData,
    };