import React, { Fragment } from 'react';

export default class Title extends React.Component{
  render(){
    const title_style = {
      backgroundColor:"#404",
      color:"#fff",
      height:50,
      fontSize:18,
      fontWeight:"bold",
      border:"5px double #fff",
      boxSizing:"border-box",
      display:"flex",
      alignItems:"center",
      padding:"0px 16px"

    }

    return(
      <Fragment>
        <div style={ title_style }>
          {this.props.label}
        </div>
      </Fragment>
    )
  }
}