import React, { Fragment } from 'react';

export default class InputArea extends React.Component{
  render(){
    const area_style = {
      margin: "0px 16px 8px 16px ",
      boxShadow: "1px 0px 3px #aaa",
      display: "flex",
      flexDirection: "column",
      borderRight:"1px solid #eee",
      borderLeft:"1px solid #eee",
      borderBottom:"1px solid #eee",
      borderTop:"3px solid #642863",
      // boxShadow:"0 0 4px rgba(0,0,0,0.2)",
      backgroundColor: "#f9f7f3",
      padding:16,
    }
  
    return(
      <div style={ area_style }>
        {this.props.children}
      </div>
    )
  }
}