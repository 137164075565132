import React from 'react';
import ValidationInputs from './ValidationInputs';
import {number_format} from '../util/commonInput';

export default class TextNumber extends React.Component {
  constructor(props) {
    super(props);
    let val = this.props.value || '0';
    if(this.props.delimiter){
      val = number_format(val,this.props.decimal,'.',',');
    }else if(this.props.decimal){
      val = number_format(val,this.props.decimal);
    };
    this.state = {
        value:val,
        // isValid:true,
        };
        // this.error = "error";
  }

  componentWillReceiveProps(nextProps){
    if(this.props.value !== nextProps.value){
        this.setState({
          value: nextProps.value
        })
    }
  }
  // componentDidMount(){
  //   this.updateValid(this.state.value)
  //  }
  
  //  updateValid = (value) =>{
  //   if(this.props.must && (value == '' || typeof(value)=='undefined')){
  //       this.setState({isValid:false});
  //   }else{
  //       this.setState({isValid:true});
  //   }
  // }

  handleInput = e => {
    let val = e.target.value;
    // 数値と.以外は削除
    if(val<0){
      val ="-" + val.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace("$#$","."); 
    }else{
      val = val.replace(/[^\d.]/g,'').replace(/^\./g,'').replace(/\.{2,}/g,'.').replace('.','$#$').replace(/\./g,'').replace("$#$","."); 
    }
    this.setState({value:val});
    // e.target.value = val;
    // this.updateValid(val);
    //this.props.onChange && this.props.onChange(this.props.name,e.target.value);
  }
  
  handleOnBlur = e=>{
        let val = e.target.value;
        if(this.props.delimiter){
            val= number_format(val,this.props.decimal,'.',',');
        }else if(this.props.decimal){
            val= number_format(val,this.props.decimal);
        }
       this.setState({value:val});
      this.props.onChange && this.props.onChange(this.props.name,val);
  }

  render() {
      
     let maxLength;
        if(this.props.maxLength){
            maxLength  = parseInt(this.props.maxLength) + (parseInt(this.props.decimal) == 0? 0:(parseInt(this.props.decimal)+1));
        }
    return (
      <div className={"textnumber "+ this.props.className }>
      {(!this.makeCheck()) && <ValidationInputs msg={(<span>{this.error}</span>)}/>}
      <input type="tel"
        name={this.props.name}
        maxLength={maxLength}
        value={this.state.value}
        onChange={this.handleInput}
        onBlur={this.handleOnBlur}
        readOnly={this.props.readOnly}
        className="textnumber-text"
        ref={this.props.inputRef}
        // isvalid ={this.state.isValid.toString()}
        // must={this.props.must && this.props.must.toString()}
        style={ Object.assign( {}, this.props.style, {backgroundColor: this.props.must? '#ffff99':'',textArign:"right"} ) }
      />
      </div>
    )
  }
}
