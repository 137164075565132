import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';

// モーダルを開く

export const openModal =( component )=>{
  const wrapper = document.body.appendChild(document.createElement('div')); // div要素を作成するための変数を宣言
  wrapper.id = 'modal'  // そのdiv要素のidに'modal'を設定
  Object.assign( wrapper.style,{ 
    position : "fixed",
    top : 0,
    height : "100vh",
    width : "100vw",
    zIndex : 1000,
    backgroundColor:'rgba(0, 0, 0, 0.3)',
  })
  ReactDOM.render( 
    <Modal close={ ()=> closeModal() } component={component} />,wrapper // <Modal />を表示
  ); 
}
// ________________________________________________________________________________________________________________

// モーダルを閉じる
  
export const closeModal =()=>{
  const modal = document.getElementById('modal'); // 作ったid('modal')のdiv要素を取得
  ReactDOM.unmountComponentAtNode( modal );       // 作ったreactDomを削除
  modal.parentNode.removeChild(modal);            // 一度親に戻って子供を参照して作ったdiv要素を削除（IE対応）
}
// ________________________________________________________________________________________________________________

class Modal extends React.Component{
  constructor(props){
    super(props);
    this.state={
      resize:false,　// trueの時にリサイズができる
      width:900,  // モーダルの幅の初期値
      height:700, // モーダルの高さの初期値
      get_X:"",   // マウスを動かした時の X座標
      get_Y:"",   // マウスを動かした時の Y座標
      start_W:"", // クリックした時点でのモーダルの幅
      start_H:"", // クリックした時点でのモーダルの高さ
      move:false,　// trueの時にモーダルを移動できる
      top:0,
      left:0,
      bottom:0,
      right:0,
      start_T:"", // クリックした時点でのモーダルのtopからの位置
      start_L:"", // クリックした時点でのモーダルのleftからの位置
      start_B:"",
      start_R:"",
    }
  }
  // __________________________________________________________________________________________________________________

  resizeStart=(e)=>{
    e.preventDefault();
    this.setState({
      get_X: e.nativeEvent.pageX,
      get_Y: e.nativeEvent.pageY,
      start_W: this.state.width,
      start_H: this.state.height,
      resize:true,
    })
  }
  // __________________________________________________________________________________________________________________

  mouseMoving=(e)=>{
    e.preventDefault();
    const {
      resize,
      width,
      height,
      start_W,
      start_H,
      get_X,
      get_Y,
      start_T,
      start_L,
      start_B,
      start_R,
      move
    } = this.state

    if(resize){
      // 以下３つの if文 はモーダルの最小幅を指定するための条件式です。数字の部分が最小幅です。高さと幅をしています。　
      if(width > 200 && height > 200 ){
        this.setState({width: start_W + (e.nativeEvent.pageX - get_X)*2});　                // 1. e.nativeEvent.pageX でマウスポインタの現在地を取得　
        this.setState({height: start_H + (e.nativeEvent.pageY - get_Y)*2});                 // 2. get_X　を引くことでどれだけ動いたかを計算
      }                                                                                       // 3. 両サイドが一度に開くので、*２をして制御
      　                                                                                      // 4. それらを start_W にプラスして width に代入
      if(width <= 200){
        // width の値を少し戻さないとリサイズできなくなってしまうので
        this.setState({width:210 });
        this.setState({resize:false});
      }
      
      if(height <= 200){
        this.setState({height: 210});
        this.setState({resize:false});
      }
    }

    if( move ){
      this.setState({top: start_T + e.nativeEvent.pageY - get_Y});
      this.setState({left: start_L + e.nativeEvent.pageX - get_X});
      this.setState({bottom: start_B - e.nativeEvent.pageY + get_Y});
      this.setState({right: start_R - e.nativeEvent.pageX + get_X});
    }
  }
  // __________________________________________________________________________________________________________________

  actionStop=(e)=>{
    e.preventDefault();
    this.setState({resize:false});
    this.setState({move:false});
  }
  // __________________________________________________________________________________________________________________


  moveStart=(e)=>{
    e.preventDefault();
    this.setState({
      get_X: e.nativeEvent.pageX,
      get_Y: e.nativeEvent.pageY,
      start_T: this.state.top,
      start_L: this.state.left,
      start_B: this.state.bottom,
      start_R: this.state.right,
      move: true,
    })
  }
  // __________________________________________________________________________________________________________________


  render(){
    const { close, component,} = this.props
    const {
      width,
      height,
      top,
      left,
      bottom,
      right,
    } = this.state
    // __________________________________________________________________________________________________________________

    const modalWrap_style = {
      height : '100%',
      width : "100%",
    }
    const modal_style = {
      width: width,
      maxWidth:"100vw",
      height: height,
      maxHeight:"100vh",
      position: 'fixed',
      top: top,
      left: left,
      right: right,
      bottom: bottom,
      margin: 'auto',
      backgroundColor:'white',
      overflow:"hidden",
      borderRadius:5,
    }
    const modalHeader={
      display:'flex',
      justifyContent:'flex-end', 
      alignItems:"center",
      height:32,
      paddingRight:8,
      cursor:"move",
      backgroundColor: "#556DAE" ,
    }
    const closeButton={                 // <button> モーダルの（×）ボタン
      backgroundColor:"#fff",
      border:"none",
      color:'red',
      height:24,
      width:24,
      fontSize:24,
      fontWeight:"bolder",
      cursor:"pointer",
      borderRadius:"5px",
      boxShadow:"2px 2px 2px #ccc",
    }
    const modalBody_style = {
      overflow:"auto",
      height: height - modalHeader.height,
    }
    const modalResize={
      display:"flex",
      backgroundColor:"gray",
      position:"absolute",
      height:"30px",
      width:"30px",
      bottom:"-15px",
      right:"-15px",
      pointerEvent:"none",
      cursor:"nw-resize",
      color:"white",
      transform: "rotate(45deg)",
      fontSize:"30px",
      alignItems:"center"
    }
    // ______________________________________________________________________________________________
    return(
      <Fragment>
        <div 
          style         = { modalWrap_style }
          onMouseMove   = { this.mouseMoving  }
          onMouseUp     = { this.actionStop }
          onMouseLeave  = { this.actionStop }
        >
          <div style={ modal_style }>
            <div  style={modalHeader} onMouseDown={this.moveStart}>
              <button style={closeButton} onClick={ close }>×</button>
            </div>
            <div style = { modalBody_style }> { component } </div>
            <div style={modalResize} onMouseDown={this.resizeStart}>
              ||
            </div>
          </div>
        </div>
        }
      </Fragment> 
    )
  }
}