import React from 'react';

export default class ModeBtnList extends React.Component{
  render(){
    const { mode,label="",checked } = this.props
    let btn_label
    let btn_bgColor

    switch( mode ){
      case "3" : 
        btn_label = label||"削除"
        btn_bgColor = "#a00"
      break
      default: 
        btn_label = label||"登録"
        btn_bgColor = checked?"#d56c08":"#47AD16"
      break
    }

    const def_style = {
    fontSize:14,
    color: "white",
    cursor: "pointer",
    border: "none",
    borderRadius: 5,
    fontWeight: "bold",
    padding: "5px 2vw",
    margin: "5px 0px 5px 5px",
    backgroundColor: btn_bgColor,
    }


    return(
        <button
          style={Object.assign({},def_style, this.props.hoverStyle ) } 
          onClick={ this.props.onClick }
          disabled={this.props.disabled}
          // onMouseOver = { ()=> this.setState({ hoverStyle : {opacity : "0.5" }} )}
          // onMouseLeave = { ()=> this.setState({ hoverStyle : null})}
        >
          { btn_label }
        </button>
    )
  }
}