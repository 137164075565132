/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/*　追加予定メモ

*/
import React, {Fragment} from 'react';
import Select from '../component/Select';
import SearchIcon from '../image/SearchIcon.png';
import calendar_icon from '../image/calendar_icon.png';
import {checkDate} from '../util/commonInput';
import * as Sky from "./EDIComponent";
import filter_icon from "../image/filter.svg";
import search_icon from "../image/search_icon.png";


// スタイル定義

const tbody_style = {
  // overflow:"auto",
  // maxHeight:"500px",
}

const td_style = {            // <td> 各セル
  width:"100%",
  textAlign:"center",
  verticalAlign:"middle",
  border:"1px solid #ccc",
  boxSizing:"border-box",
  transition:"0.2s",
  padding:"0px 1px",
}

const def_style = {
  width:"100%",
  backgroundColor:"transparent",
  border:"none",
  height:25,
  outline:"none",
  boxSizing:"border-box",
  padding:"0px 5px",
}

const delete_style = {            // <button> 削除ボタン
  width:"100%",
  backgroundColor:"transparent",
  border:"none",
  color:"#c55",
  fontSize:25,
  fontWeight:"bolder",
  cursor:"pointer",
}

const required_style = {         // <span> 「※必須」のスタイル
  fontSize:"11px",
  color:"red",
}

const scroll_style = {
  // overflow:"overlay",
} 

const buttonArea_style = {
  paddingTop:8,
}

const checkbox_style = {                          // <input> テキストボックスのスタイル
  width:"100%",
  backgroundColor:"transparent",
  border:"none",
  padding:"0px 5px",
  outline:"none",
  boxSizing:"border-box",
  cursor:"pointer",    // チェックボックスならカーソルを指のやつに
}
// _____________________________________________________________________________________________________


export default class EDITable extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      rows        : this.props.rows||0,              // 行数（初期値はcomponentDidMountで設定）
      cellFocus   : null,    // フォーカス中のセルの場所を保持して、スタイル適用するため
      value       : this.props.setData? this.makeValueCopy(this.props.setData):[[null]],       // テーブルのデータを保管しておく
      tableHeight : null,   // ウィンドウの高さを自動検知して、テーブルにスクロールを出す高さを計算する
      showFilter  : false,  // フィルタリングメニューの表示／非表示
      searchItems : "",     // フィルタリングの検索ボックスの値。
      filterName  : null,   // フィルタリングに使用するヘッダー情報
      filterLabel : null,   // フィルタリングメニューに表示されるヘッダー情報
      sortedData  : null,   // データをソートするときに使用される昇順／降順の管理
      pageX       : null,   // ヘッダークリック時のマウスのX座標
      pageY       : null,   // ヘッダークリック時のマウスのY座標
      tableScroll : null,   // テーブルのスクロール量
    }
    this.tableRef = React.createRef();
    this.filterMouseDown = false;                // クリックされた場所が、フィルタリングメニュー外かどうか検知するために定義。
    this.scroll_style ={
      overflow:"auto",
      position:"relative",
    }
    this.table_style = {            // <table> テーブル
      width : "100%",
      tableLayout: "fixed",
      minWidth : this.props.minWidth,
    }
    this.theader_style = {
      position: "sticky",
      zIndex: 1,
      width: "100%",
      top: 0,
      tableLayout: "fixed",
    }
    this.th_style = {                      // <td> テーブルヘッダー
      border:"1px solid #ccc",
      backgroundColor:this.props.color1,
      backgroundRepeat:"no-repeat",
      backgroundPosition:"center right",
      backgroundSize:25,
      fontSize:12,
      height:25,
      verticalAlign:"middle",
      // padding:8,
      color:this.props.fontColor,
      fontWeight:"bold",
      textAlign:"center",
    }

  }
  // ______________________________________________________________________________________________________

  componentDidMount(){
    // const {
    //   setData,
    //   rows,
    // } = this.props
    // // 初期値の設定
    // if( rows ){ this.setState({ rows: Number( rows ) }) }      // 親から引数rowsをもらって初期行数を設定しています。
    // this.setState({ value : this.makeValueCopy(setData)})
    // this.setState({ tableHeight : window.innerHeight - this.tableRef.getBoundingClientRect().top - 20 })     //
       this.setState({ tableHeight : window.innerHeight - 250 - 20,
                       backupValue:this.state.value})     //
    // this.props.bindRef && this.props.bindRef(this);
  }
  // ______________________________________________________________________________________________________
  
  shouldComponentUpdate(nextProps,nextState) {
    if (this.props.setData !== nextProps.setData) {
      const newValue =this.makeValueCopy(nextProps.setData);
      this.setState({ value :newValue,backupValue:newValue  })
    }
    return true;
  }

  componentDidUpdate(){
    if( !this.state.showFilter ){                              // フィルタリングメニューが閉じている時
      window.removeEventListener('click',this.windowClick,)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }
    if( !this.tableScroll ){
      this.tableScroll = true;                                // DidUpdateが走ると毎回レンダー時に初期値に戻ってしまうので、処理を最初の一度のみにするために変数を作成しています。
    }
  }
  // ________________________________________________________________________________________________________________

  componentWillUnmount(){
    window.removeEventListener('click',this.windowClick,)    // windowイベントwindowClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
  }
  // ________________________________________________________________________________________________________________
  // 画面をクリックした時
  
  windowClick=()=>{               
    if( this.filterMouseDown ){                  // もしフィルタリングメニュー上で、マウスダウンされていたら
      this.filterMouseDown = false;              //　filterMouseDown を初期値に戻して
      return;                                    // クリック処理を中断
    }
    this.setState({showFilter:false})            // フィルタリングメニューを閉じる
  }
  // ________________________________________________________________________________________________________________

  // フィルタリングメニュー上でマウスダウンした時

  filterOnMouseDown=()=>{ 
    this.filterMouseDown = true;                  // フィルタリングメニュー上でマウスダウンされたことを検知
  }
  // ________________________________________________________________________________________________________________

  // ヘッダーをクリックした時

  headerClick =(e,header,filterName,shouldFilterShow)=>{                
    if(!shouldFilterShow){                                          
      this.setState({ showFilter : false });                // フィルタリングメニューを閉じて
      return;                                               // 処理を中断
    }

    window.addEventListener('click',this.windowClick);      // windowイベントwindowClick関数を作成します。

    if ( window.innerWidth < e.pageX + 200){                // 「ブラウザの横幅」 ＜　「クリックしたX座標」＋「フィルタリングメニューの横幅」　
      this.setState({ pageX : e.pageX - 200 })              // クリック時に右側に表示されるメニューを、左側に表示させる（画面の右の方でクリックしてもデザインが崩れないように…）
    }else{                                                  // それ以外なら
      this.setState({ pageX : e.pageX })                    // 普通に表示（スタイル時に使用）
    }           

    this.setState({ 
      pageY       : e.pageY,      // クリックされたY座標を検知
      showFilter  : true,         // フィルタリングメニューを表示
      filterName  : filterName,      // フィルターをかけるためのデータのKey情報をセット
      filterLabel : header,       // フィルタリングメニューにヘッダー名を表示
      searchItems : "",           // フィルタリングメニューの検索ボックスを初期化
    })    
  }

  // ______________________________________________________________________________________________________
  
  // ヘッダーの上にマウスを乗せた時("in")、外した時("out")　（ホバーアクション）

  headerHover =(e,action,shouldFilterShow)=> {              // 引数・・・"in"か"out"、親から設定されているタイプ、バインドされているKey情報、
    if(shouldFilterShow){
      const style = e.target.style;                          // 省略宣言
      switch(action){
        case "in" : // onMouseOver時のスタイル
          style.backgroundImage = `url(${filter_icon})`;     // アイコンを表示    
          style.cursor          = "pointer";                 // カーソルを指のやつに
          style.backgroundColor = "#ddd";                    // 背景色を変更
        break;
        case "out": // onMouseLeave時のスタイル
          style.backgroundImage = "" ;                       // アイコンを非表示（IEではnullが上手く動作しないので...）
          style.backgroundColor = Sky.TABLECOLOR1;         // 背景色を戻す
        break;
        default   : break;
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // 検索ボックスに値を入力した時 フィルタリング処理

  searchChange =(e)=>{
    const { filterName,value } = this.state    // state省略宣言
    let filterdData = value.filter( item  => {
      return( item[filterName].toLowerCase().search(e.target.value.toLowerCase()) !== -1);
    })
    
    this.setState({ value : filterdData })          // 絞り込み後のデータをセット
    this.setState({ searchItems : e.target.value })   // 自身の値を更新するための処理             
    this.setState({ startRows : 0 })                  // ページの開始行をリセット。
    this.setState({ paging : 1})                      // ページ数カウントを初期化。（データがないのにページがそのままにならないように）

  }
  // _______________________________________________________________________________________________________

  // onKeyDownイベント
  closeFilter=(e)=>{
    if( e.keyCode === 13){  // Enterを押した時
      this.setState({ 
        showFilter  : false,  // フィルターを閉じる
        searchItems : "",     //　検索結果をリセット
      }) 
    }
  }
  // _______________________________________________________________________________________________________

  // ソート用のボタンを押した時（データをソートする処理）
  
  sort =( orderBy )=>{                           // 引数・・・"up"か"down"
    const { value, filterName } = this.state    // state省略宣言
    let sortedData = null;                        // ソートをした後のデータ

    switch ( orderBy ){
      case "down" : // 降順
        sortedData = value.sort( function(a, b){    
          if ( a[filterName] > b[filterName] ) { return 1; } 
          else { return -1; }
        })
      break;

      case "up" : // 昇順
        sortedData = value.sort( function(a, b){
          if ( a[filterName] < b[filterName] ) { return 1;} 
          else { return -1 }
        })
      break;

      default : break;
    }

    this.setState({ value : sortedData })      // 並び替え後のデータをセット
    this.setState({ showFilter : false   })      // ボックスを閉じる
  }
  // _______________________________________________________________________________________________________

  // 「検索結果のリセット」ボタンを押した時

  resetFilter =()=> {
    this.setState({ 
      value    : this.state.backupValue,
      showFilter : false,  // フィルタリングメニューを閉じる
    })
  }
  // _______________________________________________________________________________________________________

  makeValueCopy=(setData)=>{
    let value_copy = [];
    if( setData.length > 0 ){
      for(let i = 0;i<setData.length;i++){
        let {...newArr} = setData[i];
        newArr['ediChecked']='0';
        newArr['rowIndex']=i;
        value_copy.push(newArr);
      }
    }
    return value_copy;
  }
  // ______________________________________________________________________________________________________
  //return all the datas
  getTableData = () => {
    return this.state.value;
  }
  //return checked records only
  getCheckedTableData =() => {
    let returnArr = new Array()
    for(let i = 0;i< this.state.value.length;i++){
      if(this.state.value[i]['ediChecked'] === '1')returnArr.push(this.state.value[i])
    }
    return returnArr;
  }
  // ______________________________________________________________________________________________________
  handleOnCheck = (rowIndex,name,value) =>{
    this.state.value[rowIndex][name] = value;
    this.setState({
      value: this.state.value
    })
  }
  // 「行追加」ボタンを押した時
  addRows =(e,rowData,newRowData,shouldRowAdd)=>{
    if(shouldRowAdd(this.state.value,rowData)){
      let newArr= this.state.value.slice();
      let idx = rowData.rowIndex
      let isSameGroup = true
      if( idx >= 0){
        let concat2 = [];
        for(var start = idx+1;start<newArr.length;start++){
          let {...v} = newArr[start]
          //追加した行は下に積むため
          if(isSameGroup){
            if(v.rowAdded){
              idx = v.rowIndex;
              continue;
            }else{
              isSameGroup = false;
            }
          }
          v.rowIndex = v.rowIndex + 1;
          //データ元行番号記憶
          v.parentRowIndex = v.parentRowIndex + 1;
          concat2.push(v);
        }
        let newOne = newArr.slice(0,idx+1).concat(Object.assign({},newRowData,
          {rowIndex:idx+1, rowAdded:true,parentRowIndex:rowData.rowIndex}),concat2)
        this.setState({value :newOne})
      }else{
        newArr.splice(this.state.rows,0,Object.assign({},newRowData,
          {rowIndex:this.state.rows + 1, rowAdded:true,parentRowIndex:this.state.rows}))
        this.setState({value : newArr})
      }
      // let tableTop = this.tableRef.getBoundingClientRect().top               // tableの位置（Y座標）
      // this.setState({ tableHeight : window.innerHeight - tableTop - 20 })     //
      this.setState({ rows  : this.state.rows + 1});    // 行数を追加しています。
    }
  }
  // _______________________________________________________________________________________________________

  // 「行を減らす」ボタンを押した時
  takeOutRow =(e,idx)=>{
    let newArr= this.state.value.slice();
    if(idx >= 0){
      let concat2 = [];
      for(var start = idx+1;start<newArr.length;start++){
        let {...v} = newArr[start]
        v.rowIndex = v.rowIndex - 1;
        if(v.parentRowIndex > idx+1) v.parentRowIndex = v.parentRowIndex-1;
        concat2.push(v);
      }
      let newOne = newArr.slice(0,idx+1).concat(concat2)
      this.setState({value :newOne})
    }else{
      newArr.pop();
      this.setState({value : newArr})
    }
    let tableTop = this.tableRef.getBoundingClientRect().top               // tableの位置（Y座標）
    this.setState({ tableHeight : window.innerHeight - tableTop - 20 })     //
    this.setState({ rows  : this.state.rows - 1});    // 行数を追加しています。
  }
  // _______________________________________________________________________________________________________
  
  // 「行削除」ボタンを押した時

  delRow =(row)=>{
    const value_copy = this.state.value.slice();
    for( let i = 0; i < value_copy[row].length; i++ ){
      value_copy[row][i] = "";
    }
    this.setState({ value : value_copy })
  }
  // _______________________________________________________________________________________________________

  handelOnChange =(date,i,binding)=>{
    let value_copy = this.state.value.slice();
    value_copy[i][binding] = date
    this.setState({ value : value_copy })
  }

  updateRowData = (rowIndex,data) => {
    let value_copy = this.state.value.slice();
    value_copy[rowIndex] = data;
    this.setState({ value : value_copy })
  }
// ___________________________________________________________________________________________________________
  makeCell = (setData,format,i,j)=>{
    const {
      binding,
      value,type,
      width,height,disabled,align,
      forceEdit,
      option,contents}= format

    let   setValue    = '';                 // 親から渡されたデータを適切なフォームに割り振るための変数
    let   setDisabled = false;
    if( setData && setData[i]){           // （親から）setDataが設定されていたら
      
      if(typeof(value) === 'function'){
        setValue = value(setData[i])
      }else if(typeof(binding) !== 'undefined'){
        if(Array.isArray(binding)){
          for(let v of binding){
              if(!setData[i][v])continue;
              if(setValue !== '') setValue += '-';
              setValue += setData[i][v];
          }
        }else{
          setValue =　setData[i][binding]       // （親から）setDateに対してbindingで設定
        }
      }else{
        setValue = value
      }
    }
    if( this.props.modalData && i === this.state.modalRow ){
      setValue  = this.props.modalData[binding]
    }

    if(typeof(disabled) === 'function'){
      setDisabled = disabled(setData[i])
    }else{
      setDisabled = disabled;
    }
    // （親から）typeによってセル内に配置する要素を設定しています
    switch(type){
      
      case("header"):                                              // ヘッダー
      //ヘーダ―のHeightで行の高さを特定するよう
        return <td key={"detailTd_"+i+"_"+j} style = {Object.assign({},this.th_style, {width:width,height:height,})} >{ i + 1 }</td> 

      case("check"):                                               // チェックボックス
        return <CheckBox checked={setValue} onClick={(name,value) => {
          this.handleOnCheck(i,'ediChecked',value);
          if(forceEdit){
            forceEdit(setData[i]);
            this.updateRowData(i,setData[i]);
          }
        }} disabled={setDisabled} />

      case("select"):                                              // セレクトボックス
        return <Select width="100%" style={def_style} disabled={setDisabled} value={setValue}>
          {option}
        </Select>
      
      case("date"):                                                 // 日付けカレンダー
        return   <Calendar width="100%" style= {Object.assign({},def_style,{textAlign:align})} disabled={setDisabled} value={setValue} onChange={ (data) => this.handelOnChange(data,i,binding) }/> 

      case("text"):                                                //　テキストボックス
        return  <TableInput disabled={setDisabled} value={setValue} onChange={ (data) => this.handelOnChange(data,i,binding) }/>

      case("number"):                                                //　テキストボックス(数値)
        return  <TableInput number disabled={setDisabled} value={setValue} onChange={ (data) => this.handelOnChange(data,i,binding) }/>

      case("modal"):                                                  // モーダル
        return   <TableModal color={ this.props.color1 } onClick={()=>this.setState({ modalRow : i })}>{contents}</TableModal>
      
      case("delete"):                                              // 行削除ボタン
       return <button style={delete_style} onClick = {this.delRow(i)}>×</button>

      default :                                        // デフォルト
        if(typeof(type)==='function'){
          return type(setData[i],this)
        }else{
          return type                             // type指定したもの（コンポーネントなど）
        }
    }
  }  


render(){
    /*
     列タイトルの定義：this.props.headerColumns
     列の定義：this.props.columns
     列の内部細かく分割する場合：this.props.columns.detailColumns
     行ごとのデータ：this.state.value
    */
    let children    = this.props.columns
    const headerColumns = this.props.headerColumns
    const setData     = this.state.value    // 親から渡されたデータの集合体
    const headerArr   = [];                   // 親から渡されたheaderを入れて、表示するための配列
    let   typeArr     = [];                   // 親から渡されたtypeを入れて、セルに挿入するための配列
    let   trArr       = [];
    let   tdArr       = [];
    let   tr_color    = null;                 // 行の背景色を変更するための変数
    let   hover_style = null;                 // オンマウス中のセルのスタイル
    const cellFocus   = this.state.cellFocus  // 省略宣言
    let   focus_style = null;                 // フォーカス中のセルのスタイル
// _____________________________________________________________________________________________________
    // 初期値用の for ループです。 親からJson形式で渡された中身によって項目を設定しています
    for(let i = 0; i < headerColumns.length; i++){
        if(headerColumns[i].label){                                               // headerが指定されていなかったら処理をスキップ（セル結合をしている）
          headerArr.push(                                                     // <th>テーブルヘッダーを作成
            <td
              key = { "header_"+i }
              colSpan= {headerColumns[i].colSpan}                                       // （親から）colsでセル結合
              style = {Object.assign({},this.th_style, {width:headerColumns[i].width})}   // （親から）widthでセル幅指定
              onClick      = { (e)=> this.headerClick(e,headerColumns[i].label,headerColumns[i].filterName,headerColumns[i].filter) }
              onMouseOver  = { (e)=> this.headerHover(e,"in",headerColumns[i].filter) }
              onMouseLeave = { (e)=> this.headerHover(e,"out",headerColumns[i].filter) }
              onMouseDown  = { this.filterOnMouseDown }
            >
              {headerColumns[i].label}  
              {headerColumns[i].required &&                      // （親から）requiredが設定されていたら、
                <span style={this.required_style}>*</span>   // 「※必須」をヘッダーに表示
              } 
            </td>
          )
      }
    }
    // _________________________________________________________________________________________________________
    for(let i = 0; i < this.state.rows; i++){
            tdArr = [null];
            if(typeof(setData[i]) === 'undefined')continue;
            for(let j = 0; j < children.length; j++){
              if(typeof(children[j].detailColumns) === 'function'){
                  let details = children[j].detailColumns(setData[i])  //分割Cell
                  //分割Cellの場合
                  for(let nums = 0;nums<details.length;nums++){
                    typeArr[j+nums] = this.makeCell(setData,details[nums],i,details[nums].binding)
                    tdArr.push(                     // typeに基づいて、セルの作成
                      <td 
                        key={"detailTd_"+i+"_"+j+"_"+nums}
                        colSpan= {details[nums].colSpan}    
                        style       = { Object.assign({},td_style, hover_style, focus_style,{
                          width:details[nums].width,
                          textAlign:details[nums].align,}) } 
                        onFocus     = { ()=> this.setState({ cellFocus: i+'_'+j+'_'+nums }) } 
                        onBlur      = { ()=> this.setState({ cellFocus: null }) }
                      >
                        {typeArr[j+nums]}
                      </td>
                    )
                  }
              }else{
                  typeArr[j] = this.makeCell(setData,children[j],i,j)
                  if(children[j].type === 'header'){
                    tdArr.push(typeArr[j])
                    continue;
                  }
                  tdArr.push(                                                   // typeに基づいて、セルの作成
                    <td 
                      key={"detailTd_"+i+"_"+j}
                      colSpan= {children[j].colSpan}
                      style       = { Object.assign({},td_style, focus_style,{
                        width:children[j].width,
                        textAlign:children[j].align,}) } 
                      onFocus     = { ()=> this.setState({ cellFocus: i+'_'+j }) } 
                      onBlur      = { ()=> this.setState({ cellFocus: null }) }
                    >
                      {typeArr[j]}
                    </td>
                  )
              }
            }
            // if( cellFocus !== null && i === cellFocus.match(/(\S*)_/)[1]){
              //    tr_color = {backgroundColor:"#CEF6D8"} 
              // }else{
            if( setData[i].rowAdded){
              tr_color = {backgroundColor:"#DFFFDF"} 
            }else{
              tr_color = {backgroundColor:"#fff"}    // tr_colorの初期化                                                    
              if(this.props.oddRowColor && i % 2 !== 0){ tr_color = {backgroundColor: this.props.oddRowColor } }  // 奇数行に背景色を設定           
            }
            if(this.props.customizedRowColor){
              tr_color = this.props.customizedRowColor(setData[i])
            }
            trArr.push(                                                     　// 行の作成
              <tr key={i} style = {tr_color}>
                {tdArr}
              </tr>
            )
    }

    const filter_style = {            // <div> フィルターの外枠
      width:200,
      position:"fixed",
      display:"inline-flex",
      flexDirection:"column",
      alignItems:"center",
      top :this.state.pageY,
      left:this.state.pageX,
      backgroundColor:"#fff",
      border:"1px solid #aaa",
      boxShadow:"0px 0px 2px 0px #aaa",
      padding:8,
      borderRadius:3,
      transition:"0.2s",
      zIndex: 5,
    }
    const search_style = {            // <input> 検索テキストボックス
      background:`url(${search_icon}) no-repeat`,
      backgroundPosition:"center left 5px",
      backgroundSize:"18px 18px",
      height:25,
      width:"100%",
      margin:"8px 0px",
      boxSizing:"border-box",
      paddingLeft:30,
      border:"1px solid #aaa",
      borderRadius:5,
    }
    return(
      <div>
        <div style= { Object.assign({},this.scroll_style,{height:this.state.tableHeight || window.innerHeight - 200
          ,width:'100%',})} >
            <table style={ this.theader_style }>
              <thead>
                <tr>{headerArr}</tr>
              </thead>
            </table>
            <table style={ this.table_style }>
              <tbody 
                ref          = { e=>this.tableRef = e}
                // onMouseLeave = { ()=> this.setState({ cellHover: null, }) } 
              >
                {trArr}
              </tbody>
            </table>
        </div>
        {/* <div style = { buttonArea_style } >
          <button style = {this.rowsButton_style} onClick = {(e) => this.addRows(e)}>行を追加する</button>
          <button style = {this.rowsButton_style} onClick = {(e) => this.takeOutRow(e)}>行を減らす</button>
        </div> */}
        { this.state.showFilter && 
            <div 
              style       = { filter_style }
              onMouseDown = { this.filterOnMouseDown }
            >
              <Fragment>
              <div >{this.state.filterLabel}</div>
              <input 
                style        = { search_style } 
                value        = { this.state.searchItems } 
                onChange     = { this.searchChange } 
                onKeyDown    = { this.closeFilter  }
                placeholder  = "Search..."
                autoFocus
              />
              <MenuButton onClick = { this.resetFilter  } label="検索結果のリセット"  anyProps={{color:Sky.TABLECOLOR1,fontColor:Sky.TABLECOLORF}}/>
              <MenuButton onClick = { () => this.sort("down") } label="A→Zで並び替え" anyProps={{color:Sky.TABLECOLOR1,fontColor:Sky.TABLECOLORF}}/>
              <MenuButton onClick = { () => this.sort("up")   } label="Z→Aで並び替え" anyProps={{color:Sky.TABLECOLOR1,fontColor:Sky.TABLECOLORF}}/>
            </Fragment>
            </div>
          }
      </div>

    );

  }
}

// テーブル内でスクロール
// モーダルで自動データ挿入
// 計算処理
// focusのセル色変更
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// テーブル用インプット
class TableInput extends React.Component{
  constructor(props){
    super(props);
    this.input_ref = React.createRef();
  }
  // __________________________________________________________________________________________________________________

  componentDidMount(){
    if( this.props.number ){ this.input_ref.current.type = "tel" }   // numberが設定されていたら"tel"型に。（自動で半角入力に切り替わるので）
    if( this.props.check  ){ 
      this.input_ref.current.type    = "checkbox" ;                  // チェックボックスを指定
      this.input_ref.current.checked = this.props.checked            // checkedを参照。
    }
    if( this.props.value  ){ 
      this.input_ref.current.value = this.props.value   // 親からvalueを参照 
      this.keepvalue = this.props.value
    }     
  }
  // __________________________________________________________________________________________________________________

  componentDidUpdate(){
    if( this.props.value !== this.keepvalue ){ 
      this.input_ref.current.value = this.props.value 
      this.keepvalue = this.props.value
    }
  }

  shouldComponentUpdate(nextProps,nextState){
    if(this.props.value != nextProps.value){
      return true
    }
    return false 
  }
  // _________________________________________________________________________________________________________________

  // チェックボックスをEnterでも操作できるようにする処理。　キーダウン時
  handleKeyDown=(e)=>{
    if( e.target.type === "checkbox" && e.keyCode === 13 ){  // チェックボックスかつ、Enterをクリックしたなら
      this.input_ref.current.checked = !e.target.checked ;   // trueとfalseを入れ替え
    }
  }
  // __________________________________________________________________________________________________________________

  handleChange =( e )=>{
    //this.setState({ value: e.target.value })
    this.props.onChange && this.props.onChange(e.target.value)
  }

  render(){
    let text_style = {                          // <input> テキストボックスのスタイル
      width:"100%",
      backgroundColor:"transparent",
      border:"none",
      height:25,
      outline:"none",
      boxSizing:"border-box",
      padding:"0px 5px",
      textAlign:this.props.number? "right": null,   // 数値型なら文字を右寄せに
    }

    if(this.props.disabled !== true ){
      text_style.backgroundColor = 'ghostwhite';
      text_style.border = '1px solid darkgrey';
      text_style.borderRadius = 5;
    }


    // __________________________________________________________________________________________________________________

    return(
      <input 
        ref       = { this.input_ref }
        style     = { text_style } 
        onKeyDown = { this.handleKeyDown }
        readOnly  = { this.props.disabled }
        onChange  = { this.handleChange }
        defalutvalue = {this.props.value}
      />
    )
  }
}


class CheckBox extends React.Component{
  shouldComponentUpdate(nextProps,nextState){
    if(nextProps.checked !== this.props.checked){
      return true;
    }
    return false;
  }
  handleOnClick = () =>{
    this.props.onClick && this.props.onClick('ediCheck',this.props.checked === '1'?'0':'1' );
  }
  render() {
    const boxColor = this.props.color? this.props.color:"#777";
    const wrap = {
      display : "flex",
      alignItems:"center",
      height:"100%",
      justifyContent:"center"
    }

    const boxWrap = {
      display:"inline-flex",
      padding:0,
      border:"none",
      backgroundColor:"transparent",
      alignItems:"center",
      boxSizing:"border-box",
      cursor:this.props.disabled ? null : "pointer",
      fontSize:16,
    }

    const box = {
      height:20,
      width:20,
      backgroundColor: this.props.checked === '1' ? boxColor : "white",
      boxSizing:"border-box",
      borderRadius:5,
      borderStyle:"solid",
      borderWidth:"2px",
      borderColor:boxColor,
      position:"relative",
    }

    const checkedStyle = {
      position:"absolute",
      bottom:2,
      left:3,
      boxSizing:"border-box",
      height:15,
      width:10,
      borderRight: "4px solid white",
      borderBottom: "4px solid white",
      transform: "rotate(45deg)",
      borderRadius:5,
    }
    // ________________________________________________________________________________


    
    return (
      <div style={ wrap }>
        <label>{ this.props.label }</label>
        <button style={ boxWrap } onClick={this.handleOnClick} disabled={ this.props.disabled }>
          <div style = { box }>
            { this.props.checked === '1' && <div style = { checkedStyle }></div> }
          </div>
        </button>
      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class TableModal extends React.Component{
  constructor(props){
    super(props);
    this.state={
        modalopen:false,   // trueの時モーダルが開く
        resize:false,　// trueの時にリサイズができる
        width:800,  // モーダルの幅の初期値
        height:600, // モーダルの高さの初期値
        get_X:"",   // マウスを動かした時の X座標
        get_Y:"",   // マウスを動かした時の Y座標
        start_W:"", // クリックした時点でのモーダルの幅
        start_H:"", // クリックした時点でのモーダルの高さ
        move:false,　// trueの時にモーダルを移動できる
        top:0,
        left:0,
        bottom:0,
        right:0,
        start_T:"", // クリックした時点でのモーダルのtopからの位置
        start_L:"", // クリックした時点でのモーダルのleftからの位置
        start_B:"",
        start_R:"",
    }
  }
  // __________________________________________________________________________________________________________________


  closeModal=(e)=>{
      e.preventDefault();
      this.setState({ modalopen: false })
      // 初期化用 setState です
      this.setState({
          width:800,
          height:600,
          top:0,
          left:0,
          bottom:0,
          right:0,
      })
      this.openBtnRef.focus();
  }
  // __________________________________________________________________________________________________________________

  
  openModal=(e)=>{
      e.preventDefault();
      this.setState({ modalopen:true });
      this.props.onClick && this.props.onClick();
  }
  // __________________________________________________________________________________________________________________


  resizeStart=(e)=>{
      e.preventDefault();
      this.setState({
          get_X: e.nativeEvent.pageX,
          get_Y: e.nativeEvent.pageY,
          start_W: this.state.width,
          start_H: this.state.height,
          resize:true,
      })
  }
  // __________________________________________________________________________________________________________________

  mouseMoving=(e)=>{
      e.preventDefault();
      const {
        resize,
        width,
        height,
        start_W,
        start_H,
        get_X,
        get_Y,
        start_T,
        start_L,
        start_B,
        start_R,
        move
      } = this.state

      if(resize){

          // 以下３つの if文 はモーダルの最小幅を指定するための条件式です。数字の部分が最小幅です。高さと幅をしています。　
          if(width > 200 && height > 200 ){
              this.setState({width: start_W + (e.nativeEvent.pageX - get_X)*2});　                // 1. e.nativeEvent.pageX でマウスポインタの現在地を取得　
              this.setState({height: start_H + (e.nativeEvent.pageY - get_Y)*2});                 // 2. get_X　を引くことでどれだけ動いたかを計算
          }                                                                                       // 3. 両サイドが一度に開くので、*２をして制御
          　                                                                                      // 4. それらを start_W にプラスして width に代入
          if(width <= 200){
              // width の値を少し戻さないとリサイズできなくなってしまうので
              this.setState({width:210 });
              this.setState({resize:false});
          }
          
          if(height <= 200){
              this.setState({height: 210});
              this.setState({resize:false});
          }
      }

      if( move ){
          this.setState({top: start_T + e.nativeEvent.pageY - get_Y});
          this.setState({left: start_L + e.nativeEvent.pageX - get_X});
          this.setState({bottom: start_B - e.nativeEvent.pageY + get_Y});
          this.setState({right: start_R - e.nativeEvent.pageX + get_X});
      }
  }
  // __________________________________________________________________________________________________________________

  actionStop=(e)=>{
      e.preventDefault();
      this.setState({resize:false});
      this.setState({move:false});
  }
  // __________________________________________________________________________________________________________________


  moveStart=(e)=>{
      e.preventDefault();
      this.setState({
          get_X: e.nativeEvent.pageX,
          get_Y: e.nativeEvent.pageY,
          start_T: this.state.top,
          start_L: this.state.left,
          start_B: this.state.bottom,
          start_R: this.state.right,
          move: true,
      })
  }
  // __________________________________________________________________________________________________________________


  render(){
    // スタイル記述欄 
    const modalButton={
      backgroundColor:"transparent",
      background:`url(${SearchIcon}) no-repeat`,
      backgroundPosition:"center",
      backgroundSize:"16px 16px",
      border:"none",
      cursor:"pointer",
      height:25,
      width:"100%",
    }
    const modalWrap={
      position:"fixed",
      zIndex:999,
      width: '100%',
      height: '100%',
      left: '0px',
      top: '0px',
      right: '0px',
      bottom: '0px',
    }
    const modalBg={                 // <div> モーダルの背景の黒い部分
      position:"relative",
      width: '100%',
      height: '100%',
      backgroundColor:'black',
      opacity:"0.3",
    }
    const modal={
      width: this.state.width + "px",
      maxWidth:"100%",
      height: this.state.height + "px",
      maxHeight:"100%",
      position: 'fixed',
      top: this.state.top + "px",
      left: this.state.left + "px",
      right: this.state.right + "px",
      bottom: this.state.bottom + "px",
      margin: 'auto',
      backgroundColor:'white',
      overflow:"hidden",
      borderRadius:5,
    }
    const modalHeader={
      display:'flex',
      justifyContent:'flex-end', 
      alignItems:"center",
      height:32,
      paddingRight:8,
      cursor:"move",
      backgroundColor: this.props.color ,
    }
    const closeButton={                 // <button> モーダルの（×）ボタン
      backgroundColor:"#fff",
      border:"none",
      color:'red',
      height:24,
      width:24,
      fontSize:24,
      fontWeight:"bolder",
      cursor:"pointer",
      borderRadius:"5px",
      boxShadow:"2px 2px 2px #ccc",
    }
    const modalBody={
      overflow:"auto",
      height: this.state.height - 32 +"px",
    }
    const modalResize={
      display:"flex",
      backgroundColor:"gray",
      position:"absolute",
      height:"30px",
      width:"30px",
      bottom:"-15px",
      right:"-15px",
      pointerEvent:"none",
      cursor:"nw-resize",
      color:"white",
      transform: "rotate(45deg)",
      fontSize:"30px",
      alignItems:"center"
    }
    // ______________________________________________________________________________________________
    return(
        <Fragment>
            <button onClick={this.openModal} style={modalButton} ref={ (e)=> this.openBtnRef = e }>　</button>
            { this.state.modalopen &&
            <div 
              style         = { modalWrap }
              onMouseMove   = { this.mouseMoving  }
              onMouseUp     = { this.actionStop }
              onMouseLeave  = { this.actionStop }
            >
                <div style={modalBg} ></div> 
                <div style={modal}>
                    <div  style={modalHeader} onMouseDown={this.moveStart}>
                        <button style={closeButton} onClick={this.closeModal}>×</button>
                    </div>
                    <div style={modalBody}> {this.props.children} </div>
                    <div style={modalResize} onMouseDown={this.resizeStart}>
                        ||
                    </div>
                </div>
            </div>
            }
        </Fragment>
      
      )
  
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// スタイル記述欄    
const text_wrap = {            // <div>：テキストのwrap
  display:"inline-flex",
  flexDirection:"column",
  position:"relative",
  width: '100%'
}



const calendar_header = {                  // <div>：カレンダーのヘッダー
  display:"flex",
  justifyContent:"space-between", 
  alignItems:"center",
  borderRadius:"inherit",
}

const changeMonth = {                       // <button>：月を切り替えるボタン
  border:"none",
  backgroundColor:"transparent",
  padding:8,
  fontSize:"20px",
  cursor:"pointer",
}

const calendar_title = {                // <button>：カレンダーのタイトル（年月）
  padding:8,
  backgroundColor: "transparent",
  border :"none",
  fontSize:"16px",
  whiteSpace: "nowrap",
  cursor:"pointer",
}

const week_low = {                      // <div>：カレンダーの曜日が並ぶ列
  display:"flex", 
  alignItems:"center",
}

const date_wrap = {
  height:"100%",
}

const date_style = {                    // <button>:当月の日付けのstyle
  backgroundColor:"transparent", 
  minWidth:"14.285%",
  height:25,
  fontSize:12,
  border:"none",
  cursor:"pointer",
  borderRadius: 5,
  outline: "none",
}

const dayname_style={                 // <button> : 曜日のスタイル
  minWidth:"14.285%",
  height:25,
  fontSize:12,
  border:"none",
  borderRadius: 5,
  cursor:"pointer",
  backgroundColor:"transparent",
  fontWeight:"bold",
}

const preDate_style = {              // <button>:先月の残りの日付けのスタイル
  minWidth:"14.285%",
  height:25,
  fontSize:12,
  border:"none",
  borderRadius: 5,
  color:"#aaa",
  cursor:"pointer",
}

const nextDate_style = {            // <button>:次月の日付けのスタイル
  minWidth:"14.285%",
  height:25,
  fontSize:12,
  border:"none",
  borderRadius: 5,
  color:"#aaa",
  cursor:"pointer",
}

// ____________________________________________________________________________________________________________

class Calendar extends React.Component{
  constructor(props){
    super(props);
    this.state={
      value         : '',
      date          : new Date().getDate(),         // 今日の日付 
      month         : new Date().getMonth()+1,      // 表示中の月
      year          : new Date().getFullYear(),     // 表示中の年
      breakPoint    : 6,                            // 行の最後にくる曜日。（折り返し地点）
      selectedDate  : null,                         // 選択されている日付け
      cursorDate    : null,                         // キーダウンでカーソル中の日付
      showCalendar  : false,                        // カレンダーの表示／非表示
      view          : null,                         // 表示の切り替え（日付け／月）               
    };
    this.inputRef          = React.createRef();     // viewでユーザーが処理するための<input>
    this.calendarMouseDown = false;                 // clickした場所がカレンダー上かどうか確認するための変数
    this.year   = new Date().getFullYear()
    this.month  = new Date().getMonth()
    this.data   = new Date().getDate()
    this.today  = new Date ( this.year, this.month, this.data ).toString()　// 今日の日付を定義　String型にすることで条件分岐に使用できる。
  }
  // _______________________________________________________________________________________________________________________________
  componentDidMount(){
    const {value} = this.props;
      if( value && new Date( value ) != "Invalid Date" ){ // もしデータが日付として正しいなら
        this.setState({ value : value.replace( /-/g,"/")})// "-"(ハイフン)を"/"(スラッシュ)に置換
      }             // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）
      
  }

  shouldComponentUpdate(nextProps,nextState){
    if(nextProps.value !== this.props.value){
      this.setState({value:nextProps.value,}); 
    }
    return true;
  }

  //アラート退避
  componentWillUnmount = () => {
    this.setState = (state,callback)=>{
      return;
    };
  }

  componentDidUpdate(){
    if( !this.state.showCalendar ){                               // カレンダーが閉じている時
      window.removeEventListener('click',this.pageClick,)         // windowイベントpageClick関数を削除します。削除しないと画面クリックしたときに常にレンダーが走ってしまう。
    }     
  }
  // _______________________________________________________________________________________________________________________________

  // 表示している月を変える関数（directionは方向　値は　"pre" または "next" ）

  moveMonth(direction,e){
    e && e.preventDefault();

    switch(direction){
      case "pre" :    // 前
        if (this.state.month > 1) {                               // 表示月が１より大きい場合
          this.setState({month:this.state.month - 1})             // 表示月を－１します
        }
        else{                                                     // それ以外なら
          this.setState({year:this.state.year - 1})               // 表示年を－１して
          this.setState({month:12})                               // 表示月を１２月にします
        }
      break;

      case "next" :   // 次
        if (this.state.month < 12) {                              // 表示月が１２より小さい場合
          this.setState({month:this.state.month + 1})             // 表示月を＋１します
        }
        else{                                                     // それ以外なら
          this.setState({year:this.state.year + 1})               // 表示年を＋１して
          this.setState({month:1})                                // 表示月を１月にします                      
        }
      break;

      default: 
    }
  }
  // _____________________________________________________________________________________________________________

  // テキストボックスにフォーカスが当たった時

  openCalendar=()=>{
    this.setState({ focusStyle : {backgroundColor: "#eef" } })    // 背景色を変更
    if ( this.changeView ){ return }
    this.setState({showCalendar:!this.state.showCalendar})        // カレンダーを開いていたら閉じる。閉じていたら開く。
    this.setState({cursorDate: null})                             // cursorDateをnullにすることで選択した日付に自動フォーカスさせています
    window.addEventListener('click',this.pageClick,)              // windowイベントpageClick関数を作成します。
   
    //  表示する位置を切り替える処理
    if( !this.state.showCalendar ){  // hiddenboxが隠れていたら（つまり、開くとき）        
      let positionY = this.inputRef.current.getBoundingClientRect().bottom  // hiddenboxの下辺の位置を取得
      let positionX = this.inputRef.current.getBoundingClientRect().left    // hiddenboxの左辺の位置を取得
      if( positionY + 200 > window.innerHeight ){   // hiddenboxの下辺の位置 + 200(カレンダーのサイズ)が windowの縦サイズより大きいなら
        this.setState({ showTop : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showTop : false })      // boxを下部に表示する（スタイルと連携）
      }
      
      if( positionX + 250 > window.innerWidth ){   // hiddenboxの下辺の位置 + 200(カレンダーのサイズ)が windowの縦サイズより大きいなら
        this.setState({ showRight : true })       // boxを上部に表示する（スタイルと連携）
      }else{
        this.setState({ showRight : false })      // boxを下部に表示する（スタイルと連携）
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // カレンダー上またはテキストボックスでマウスダウンした時 

  handleOnMouseDown=()=>{ 
    this.calendarMouseDown = true;                                // カレンダー上またはテキストボックスでマウスダウンされたことを維持
  }
  // ________________________________________________________________________________________________________________

  // 画面をクリックした時 

  pageClick=()=>{               
    if( this.calendarMouseDown ){                                 // もしカレンダー上、またはテキストボックスでマウスダウンされていたら
      this.calendarMouseDown = false;                             //　calendarMouseDown を初期値に戻して
      return;                                                     // クリック処理を中断
    }               
    this.setState({showCalendar:false})                           // カレンダーを閉じる
  }
  // ________________________________________________________________________________________________________________

  // 日付けカーソルを動かす処理 onKeydown

  moveDate=(e)=>{
    if( !this.state.showCalendar ){ return }                              // カレンダーが閉じているときにreturnさせて、処理をさせないためのif文です
    let   nowDate       = null;                                           // 現在カーソルされている日付け
    let   nextDate      = null;                                           // ビューに反映させるために、キーダウン後の日付けを保持する
    const cursorDate    = this.state.cursorDate;                          // 省略宣言
    const selectedDate  = this.state.selectedDate;                        //  :
    const year          = this.state.year;                                //  :
    const month         = this.state.month;                               //  :
    const lastDate      = new Date(year, month, 0).getDate();             // 月の最終日を取得（月ページング処理のため）
    
    if(cursorDate){        nowDate = new Date(cursorDate).getDate(); }     // 既にカーソルの当たっている日付けがあれば、その日を取得 
    else if(selectedDate){ nowDate = new Date(selectedDate).getDate(); }   // 日付けが選択されていれば、その日を取得
    else if(this.today){   nowDate = new Date(this.today).getDate(); }     // 今日の日付がカレンダー上にあれば取得

    switch(e.keyCode){

      case 37 :  // 左
        if( nowDate <= 1 ) { this.moveMonth("pre") }
        nowDate -= 1;
      break;

      case 38 :　// 上
        if( nowDate - 7 < 1 ) { this.moveMonth("pre") }
        nowDate -= 7;
      break;

      case 39 :　// 右
        if( nowDate >= lastDate ) { this.moveMonth("next") }
        nowDate += 1;
      break;

      case 40 :　// 下
        if( nowDate + 7 > lastDate ) { this.moveMonth("next") }
        nowDate += 7;
      break;

      case 13 : // Enterキー
      case 32 : // スペースキー
        if( cursorDate ){ this.setState({ selectedDate : cursorDate }); }     // カーソルされている日付けがあれば、それを選択する。
        this.setState({showCalendar:false});                                  // カレンダーを閉じる
        const YYYY = year ;                                                   // 年を抽出
        const MM   = ("0" + this.state.month ).slice(-2);                     // 月をMM方式で抽出（ ("0" + " ").slice(-2) とすることで頭に０をつけることができる　）
        const DD   = ("0" + nowDate ).slice(-2);                              // 現在選択中の日をDD方式で抽出（ ("0"+" ").slice(-2) とすることで頭に０をつけることができる　）
        this.setState({ value: YYYY + "/" + MM + "/" + DD })
        this.props.onChange && this.props.onChange( YYYY + "/" + MM + "/" + DD )
      break;

      default :
        this.setState({showCalendar:false});                                  // カレンダーを閉じる（テキストに普通に入力処理をする場合）
      break;
    }
    nextDate = new Date( year, month - 1, nowDate);                           // 次に来る日付けをData型で取得して。
    this.setState({ cursorDate: String( nextDate ) });                        // cursorDateにセットすることでビューに反映させる
  }
  // ________________________________________________________________________________________________________________

  // 曜日ボタンを押した時

  sortDate =(e)=>{
    e.preventDefault()
    if(e.target.value <= 0){                                                  // もし曜日ナンバーが０以下（日曜日）だったら
      this.setState({ breakPoint : 6 })                                       // 曜日ナンバー６（土曜日）をブレイクポイント（週末）にセット
    }else{                                                                    // それ以外なら
      this.setState({ breakPoint : Number(e.target.value) - 1})               // 順当に前の日付をブレイクポイントにセット
    }
  }
  // ________________________________________________________________________________________________________________

  // 日付け<button>をクリックしたとき

  onClickDate =(e)=>{
    const YYYY   = new Date(e.target.value).getFullYear();      // clickした<button>のvalueから年を取得（）
    let   MM     = new Date(e.target.value).getMonth() + 1;     // clickした<button>のvalueから月を取得（）
    let   DD     = new Date(e.target.value).getDate();          // clickした<button>のvalueから日付けを取得（）
    
    this.setState({selectedDate: e.target.value});              // <button>のvalueに設定された日付けを取得（ただし、現時点ではstring型）
    
    this.inputRef.current.focus();                              // テキストボックスにフォーカスを当てる

    MM = ("0" + MM).slice(-2);                                  //("0" + MM ).slice(-2) は0を頭につけて二桁表示するためです。
    DD = ("0" + DD).slice(-2)                                   //("0" + DD ).slice(-2) は0を頭につけて二桁表示するためです。
    this.setState({ value: YYYY + "/" + MM + "/" + DD})         // 値をセットしてビューに表示
    this.handleOnChage(e,YYYY + "/" + MM + "/" + DD)
    // this.props.onChange && this.props.onChange( YYYY + "/" + MM + "/" + DD); // redux対応
  }
  // ________________________________________________________________________________________________________________
  
　// 日付けの上にマウスを乗せた時("in")、外した時("out")　（ホバーアクション）
  
  hover =(action,e)=> {
    if(e.target.value !== this.state.selectedDate && e.target.value !== this.today){                  // スタイルを上書きさせないための条件分岐
      switch(action){
        case "in" : 
          e.target.style.backgroundColor = "#ccc";
        break;
        case "out": 
          e.target.style.backgroundColor = "transparent";
        break;
        default : break;
      }
    }
  }
  // ________________________________________________________________________________________________________________

  // テキストボックスに値を入力した時
  //
  handleOnChage=(e , fromClick)=>{
    this.setState({ value: fromClick?fromClick:e.target.value });       // テキスト入力処
    this.props.onChange && this.props.onChange(fromClick?fromClick:e.target.value);　// redux対応
  }
  // ________________________________________________________________________________________________________________

  // タイトル<button>をクリックしたとき

  titleClick =(e)=>{
    e.preventDefault();
    this.setState({ view : "monthView" });  
  }
  // _________________________________________________________________________________________________________________

  // 月<button>を押した時（monthview時）

  monthOnClick =(e)=>{
    const type = this.props.type
    const lang = this.props.lang
    const YYYY = this.state.year
    const MM   = ("0" + e.target.value ).slice(-2);     //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。

    if( type === "YYYY/MM" ){
      
      if( lang === "en" ){                                  // 英語表記だったら
        this.setState({ value: e.target.name +""+ YYYY });  // jan.2019 表記でvalueにセット
      }else{                                                // それ以外
        this.setState({ value: YYYY +"/"+ MM });            // 2019/01  表記でvalueにセット
      }
      this.setState({ selectedDate : new Date( YYYY, e.target.value - 1)})
      this.setState({ showCalendar :false });               // カレンダーを閉じる

    }else{                                                  // （親から）type が指定されていなかったら
      this.setState({ month: Number( e.target.value ) });   
      this.setState({ view : "dateView" })
      this.changeView = true;
    }


    this.inputRef.current.focus();                              // テキストボックスにフォーカスを当てる
    
  }
  // _________________________________________________________________________________________________________________

  // テキストボックスからフォーカスが外れた時

  handleOnBlur =(e)=>{
    const inputValue  = e.target.value;
    const lastDate    = new Date(this.year , this.month + 1, 0).getDate();          // 今月の最終日
    let replaceValue  = null;
    let DD            = null;
    let MM            = null;
    let YYYY          = null;
    let inputLastDate = null;
    let checkData     = null;   
    switch( inputValue.length ){                                        //  入力した値の桁数に対して分岐しています
      case 1: case 2: // 1桁 または ２桁 の場合
        if( inputValue !== "0" && inputValue <= lastDate ){         // ０ではなく、今月の最終日より値が下だったら
          DD =  ("0" + e.target.value ).slice(-2);
          MM =  ("0" + this.month ).slice(-2);
          replaceValue = this.year + "/" + MM + "/" + DD
        }
        else if( inputValue[1] !== "0" && inputValue > lastDate ){   // １桁目が０ではなく、今月の最終日より値が上だったら
          DD =  ("0" + inputValue[1] ).slice(-2);
          MM =  ("0" + inputValue[0] ).slice(-2);
          replaceValue = this.year + "/" + MM + "/" + DD
        }
      break;
      
      case 3: 
        if( inputValue[1] === "/" ){                      // ２桁目に”／”が入っていたら
          DD = ("0" + inputValue[2] ).slice(-2);          // １桁目に０を追加して日付とします
        }else {                                           // それ以外なら  
          DD = inputValue[1] + inputValue[2];             // １桁目と２桁目を結合して日付とします
        }
        
        MM = ("0" + inputValue[0] ).slice(-2);            // ３桁目に０を追加して月とします
        inputLastDate = new Date(this.year, MM, 0).getDate();    // 入力した月の最終日

        if( inputValue[0] !== "0" && DD > 0 && DD <= inputLastDate){  // MM（月）が０ではなく、DD（日付）が０より上、かつ入力した月の最終日より小さかったら
          replaceValue = this.year + "/" + MM + "/" + DD             // valueをYYYY/MM/DDに置き換えます
        }
      break;
      
      case 4: case 5:
        if( inputValue[2] === "/" ){
          DD = inputValue[3] + inputValue[4];
          MM = inputValue[0] + inputValue[1];
        }else{
          DD = inputValue[2] + inputValue[3];
          MM = inputValue[0] + inputValue[1];
        }
        if(checkDate(this.year,MM,DD)){  
          replaceValue = this.year + "/" + MM + "/" + DD
        }
      break;

      case 8:
        DD   = inputValue[6] + inputValue[7]                                    // １桁目と２桁目
        MM   = inputValue[4] + inputValue[5]                                    // ３桁目と４桁目
        YYYY = inputValue[0] + inputValue[1] + inputValue[2] + inputValue[3]    // ５桁目～８桁目
        if(checkDate(YYYY,MM,DD)){  
          replaceValue = YYYY + "/" + MM + "/" + DD 
        }else{
          replaceValue = e.target.value
        }
      break;

      default: 
        replaceValue = e.target.value
      break;
    }
    this.setState({ value : replaceValue })
    this.props.onChange && this.props.onChange( replaceValue ); 
  }
  // _________________________________________________________________________________________________________________


  render(){

    const text = {                            // <input>：テキストボックス
      padding:"0px 24px 0px 5px",
      background:　this.props.disabled? 'transparent' :　`url(${calendar_icon})  no-repeat`,
      backgroundPosition:"center right 4px",
      backgroundSize:18,
      boxSizing:"border-box",
      textAlign:'center',
      pointerEvent:this.props.disabled?"none":null,
    }
    const calendar_wrap = {                   // <div>：カレンダーの親
      display: "inline-flex",
      flexDirection: "column",
      position:"absolute",
      border: "1px solid #aaa",
      top: this.state.showTop ? null: "100%",
      bottom:this.state.showTop ? "100%" : null,
      left: this.state.showRight ? null: 0,
      right:this.state.showRight ? 25 : null,
      zIndex:10,
      borderRadius:5,
      backgroundColor:"#fff",
      boxShadow:"2px 2px 2px #ccc"
    }
    // 使用変数の宣言

    const month         = this.state.month - 1;           // 当月
    const year          = this.state.year;                // 当年
    let dateArray       = [];                             // 日付を入れていくための配列
    const breakPoint    = this.state.breakPoint;          // 週の最後に来る曜日を指定する変数【０～６ : 日～土】
    // _____________________________________________________________________________________________________________
    // console.log(new Date(year,month,15).toString());
    // console.log(this.state.value)
    // マルチ言語化（曜日、年月タイトル）

    let daysLang    = [];                         // 各言語ごとに曜日を入れる配列
    let title       = null;                           // 年月タイトルを表示するための変数
    let monthsLang  = [];

    switch(this.props.lang){

      case "en"  :
        monthsLang = ["Jan.","Feb.","Mar.","Apr.","May","June","July","Aug.","Sept.","Oct.","Nov.","Dec."]
        daysLang   = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        title  = monthsLang[month] + year;                // 例：Aug.2019
      break;

      case "zh" :
        monthsLang  = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"];
        daysLang    = ["周日","周一","周二","周三","周四","周五","周六"];  
        title  = year + "年" + monthsLang[month] ;    // 例：2019年8月
      break;

      case "ja" :
      default   :
        monthsLang  = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"];
        daysLang    = ["日","月","火","水","木","金","土"];  
        title  = year + "年" + monthsLang[month] ;    // 例：2019年8月
      break;
                    
    }
    // ________________________________________________________________________________________________________________

    // 曜日を作成

    let dayArray      = [];             // 曜日と曜日No【０～６ : 日～土】と合わせるための配列
    let dayNameCount  = null;           // 曜日と曜日No【０～６ : 日～土】と合わせて配列に入れていくためのカウント変数

    if ( breakPoint === 6 ){
      dayNameCount  = 0;
    }else{
      dayNameCount  = breakPoint + 1;
    }

    for(let i = 0; i <= 6; i++){
      dayArray.push(
        <button
          key     = { dayNameCount  }
          value   = { dayNameCount  }
          style   = { dayname_style }
          onClick = { this.sortDate }
        >
          { daysLang[dayNameCount] }
        </button>
      )

      if( dayNameCount === 6 ){
        dayNameCount = 0;
      }else{
        dayNameCount ++ ;
      }
    }
    // ________________________________________________________________________________________________________________

    // 　先月の残りの日付けを作成

    const firstDayNo  = new Date(year , month, 1).getDay();         // 当月の最初の曜日番号　※getDay()で取得できるのは、0~6の番号のため
    let startDayCount = null;                                       // カレンダーの先頭に来る前月の日付けを作成するために使用するカウント変数

    if (breakPoint === 6){ startDayCount = 0 }                      // breakpointの次の曜日から週が始まるので、＋１をしています。
    else    { startDayCount = breakPoint + 1 }                      // ただしbreakpointが６（土曜日）の場合、７がないので０（日曜日）に戻します。
        
    for(let i = 0 ; i <= 5 ; i++){
      
      if(startDayCount === firstDayNo) { break }                    // もしカウントが当月の1日の曜日に達したら日付作成完了です。
      else if (startDayCount === 6)    { startDayCount = 0 }        // また、もしカウントが6の場合、次は0になります。
      else                             { startDayCount ++  }        // 当月の1日に達するまでカウントしていきます

      let madePreDate =  new Date(year , month, -i)                 // forループに基づいて作成される前月の日付け

      let select_style = {backgroundColor:"transparent"};           // 選択される前の日付の背景色を設定しています
      if( madePreDate.toString()　== this.state.selectedDate ){                // もし、作成される日付が選択されている日付けなら
        select_style = {backgroundColor:"#aaa",color:"#fff",};      // その日付けの背景色と、文字色を変更します。
      }else if( madePreDate.toString()　=== this.today ){                            // また、もし作成される日付が今日の日付けなら
        select_style = {backgroundColor:"#bbf",color:"#fff",};      // その日付けの背景色と、文字色を変更します。
      }

      dateArray.unshift(                                             // unshift()で<button>日付けを配列の手前に順に追加していきます。
        <button 
          key          = {madePreDate}
          value        = {madePreDate}
          style        = {Object.assign({}, preDate_style, select_style)}         // Object.assign()でスタイルを結合しています。
          onClick      = {(e)=> this.onClickDate(e)}
          onMouseOver  = {(e)=> this.hover("in",e)}
          onMouseLeave = {(e)=> this.hover("out",e)}
          onFocus      = {(e)=> this.hover("in",e)}
          onBlur       = {(e)=> this.hover("out",e)} 
          
        >
          {madePreDate.getDate()}
        </button>
      )

    }
    // ________________________________________________________________________________________________________________

    //  当月の日付けを作成   
    
    const lastDate = new Date(year , month + 1, 0).getDate();          // 当月の最終日

    for(let i = 1; i <= lastDate; i++){

      let madeDate      = new Date(year , month , i)                   // forループに基づいて作成される当月の日付け
      let dayNo         = madeDate.getDay();                           // 曜日を取得。dayNo=０なら日曜日、dayNo=６なら土曜日となってます。
      let dayNo_style   = null;                                        // 曜日に合わせて文字色を変えるための変数
      let select_style  = {backgroundColor:"transparent"};             // 選択される前の日付の背景色を設定しています

      if( madeDate.toString()　=== this.state.cursorDate  ){     // もし作成される日付にカーソルがあるなら
        select_style = {backgroundColor:"#ccc"};                       // その日付けの背景色と、文字色を変更します。
      }
      if( madeDate.toString() === this.today ){                  // もし作成される日付が今日の日付けなら
        select_style = {backgroundColor:"#bbf",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }
      if( madeDate.toString()　=== this.state.selectedDate ){          // もし、作成される日付が選択されている日付けなら
        select_style = {backgroundColor:"#aaa",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }

      if(dayNo === 0){ dayNo_style = {color:"#f00"} }                  // もしdayNoが０、つまり日曜日なら文字色を変更
      if(dayNo === 6){ dayNo_style = {color:"#00f"} }                  // もしdayNoが６、つまり土曜日なら文字色を変更
        
      dateArray.push(                                                  // push()で当月の日付<button>を配列の後続に入れていきます
        <button 
          key          = {madeDate}
          value        = {madeDate} 
          style        = {Object.assign({},
                            date_style, 
                            dayNo_style, 
                            select_style,
                          )}      // Object.assign()でスタイルを結合しています。
          onClick      = {this.onClickDate}
          onKeyDown    = {this.focusMove}
          onMouseOver  = {(e)=> this.hover("in",e)}
          onMouseLeave = {(e)=> this.hover("out",e)}
          onFocus      = {(e)=> this.hover("in",e)}
          onBlur       = {(e)=> this.hover("out",e)}
          
        >
        {madeDate.getDate()}
        </button>
      )

      if(dayNo === breakPoint){ dateArray.push(<br key={ "now"+i } />) }                 // 最後にbreakPoint変数で選択した曜日の後に改行を入れる
        
    }
    // ________________________________________________________________________________________________________________

    //  当月のカレンダーの末尾に来る、来月の頭の日付けを作成    

    let endDayCount = new Date(year , month + 1, 0).getDay()          // 月末の曜日Noを取得して、カウント変数にセット

    for(let i = 1; i <= 6; i++){

      if( endDayCount === breakPoint ) { break }                      // もしカウントがカレンダーの最後の曜日に達したら日付作成完了です  
      else if( endDayCount === 6 )     { endDayCount = 0 }            // また、もしカウントが6の場合、次は0になります。
      else                             { endDayCount ++  }            // 最後の曜日に達するまでカウントしていきます

      let madeNextDate = new Date(year , month + 1, i)                 // forループに基づいて作成される次月の日付け

      let select_style = {backgroundColor:"transparent"};              // 選択される前の日付の背景色を設定しています
      if( madeNextDate.toString()　=== this.state.selectedDate ){                  // もし、作成される日付が選択されている日付けなら
        select_style = {backgroundColor:"#aaa",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }else if( madeNextDate.toString()　=== this.today ){                              // もしくは、作成される日付が今日の日付けなら
        select_style = {backgroundColor:"#bbf",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }

      dateArray.push(
        <button 
          key          = {madeNextDate}
          value        = {madeNextDate}
          style        = {Object.assign({}, nextDate_style, select_style)}
          onClick      = {this.onClickDate}
          onMouseOver  = {(e)=> this.hover("in",e)}
          onMouseLeave = {(e)=> this.hover("out",e)}
          onFocus      = {(e)=> this.hover("in",e)}
          onBlur       = {(e)=> this.hover("out",e)}
        >
          {madeNextDate.getDate()}
        </button>
      )
    }

    this.changeView = false; 
    // ________________________________________________________________________________________________________________
    
    return(
      <Fragment>
        <div style={text_wrap}>
          
          <input 
            type         = "tel"
            autoComplete = "off"
            style        = { Object.assign({}, text,  this.state.hoverStyle, this.state.focusStyle, this.props.style) }
            className    = { this.props.className   } 
            readOnly     = { this.props.disabled    }
            ref          = { this.inputRef          }
            value = { this.state.value       }
              onFocus      = { (this.props.disabled)?undefined:this.openCalendar      } 
              onMouseDown  = { (this.props.disabled)?undefined:this.handleOnMouseDown }
              onKeyDown    = { (this.props.disabled)?undefined:this.moveDate          }
              onChange     = { (this.props.disabled)?undefined:this.handleOnChage     }
              onBlur       = { (this.props.disabled)?undefined:this.handleOnBlur     } 
              onClick      = { (this.props.disabled)?undefined:(()  => {this.setState({ showCalendar : true })})}
          />

          {this.state.showCalendar &&
          <div style={calendar_wrap}  onMouseDown = {this.handleOnMouseDown}>
            { this.state.view !== "monthView" &&
            <Fragment>
              <div style={calendar_header}>
                <button style={changeMonth} onClick={(e)=>{this.moveMonth("pre",e)}}>  ≪ </button>
                <button style={calendar_title} onClick={ this.titleClick　}>
                  {title}    {/* 年月タイトル */}
                </button>
                <button style={changeMonth} onClick={(e)=>{this.moveMonth("next",e)}}> ≫ </button>
              </div>
              <div style={week_low}>
                {dayArray}　      {/* 曜日 */}
              </div>
              <div style={date_wrap}>
                {dateArray}       {/* 日付け */}
              </div>
            </Fragment>
            }
            { this.state.view === "monthView" &&
            <MonthView 
              year          = { this.state.year }
              monthsLang    = { monthsLang }
              preYear       = {( )=> this.setState({ year: this.state.year - 1 }) }
              nextYear      = {( )=> this.setState({ year: this.state.year + 1 }) }
              onClick       = { this.monthOnClick }
              selectedDate  = { this.state.selectedDate }
            />
            }
          </div>
          }

        </div>
      </Fragment>
    )
    // ________________________________________________________________________________________________________________

  }
}


class MonthView extends React.Component{

  render(){

    const calendar_header = {                  // <div>：カレンダーのヘッダー
      display:"flex",
      justifyContent:"space-between", 
      alignItems:"center",
      borderRadius:"inherit",
    }

    const changeMonth = {                       // <button>：月を切り替えるボタン
      border:"none",
      backgroundColor:"transparent",
      padding:8,
      fontSize:"20px",
      cursor:"pointer",
    }

    const calendar_title = {                // <button>：カレンダーのタイトル（年月）
      padding:8,
      backgroundColor: "transparent",
      border :"none",
      fontSize:"16px",
      whiteSpace: "nowrap",
      cursor:"pointer",
    }
    
    const month_wrap = {
      height:"100%",
    }
    
    const month_style = {                    // <button>:当月の日付けのstyle
      backgroundColor:"transparent", 
      minWidth:"33.333%",
      height:30,
      fontSize:12,
      border:"none",
      cursor:"pointer",
      borderRadius: 5,
      outline: "none",
    }
    // ________________________________________________________________________________________________________________

    let   monthArr   = [];
    const monthsLang = this.props.monthsLang
    const year       = this.props.year
    const todayMonth = new Date( new Date().getFullYear(), new Date().getMonth() ).toString()
    const selectedDate   = this.props.selectedDate
    const selectedMonth  = new Date ( new Date( selectedDate ).getFullYear(), new Date( selectedDate ).getMonth() ).toString()    // 年を取得して、月を取得して、文字列に変換
     
    for( let i = 0; i < monthsLang.length; i++ ){
      const viewMonth  = new Date( year, i ).toString();

      let select_style = { backgroundColor:"transparent" };
      if( viewMonth === selectedMonth ) {
        select_style = {backgroundColor:"#aaa",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }else if(viewMonth === todayMonth){
        select_style = {backgroundColor:"#bbf",color:"#fff",};         // その日付けの背景色と、文字色を変更します。
      }

      monthArr.push(
        <button 
          key     = { monthsLang[i]   }
          style   = { Object.assign({}, month_style, select_style ) }
          value   = { i + 1  }
          name    = { monthsLang[i] }
          onClick = { this.props.onClick }
        >
          { monthsLang[i] }
        </button>
      )

      if( ( i + 1 ) % 3 === 0){
        monthArr.push( <br /> )
      }
    }

    
    return(
      <Fragment>
        <div style={calendar_header}>
          <button style={changeMonth} onClick={ this.props.preYear }>  ≪ </button>
          <button style={calendar_title}>
            { year }
          </button>
          <button style={changeMonth} onClick={ this.props.nextYear }> ≫ </button>
        </div>
        <div style={ month_wrap }>
          { monthArr }
        </div>
      </Fragment>
    )
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class MenuButton extends React.Component{
  constructor(props){
    super(props);
    this.state = {
      hover : false
    }
  }
  // ________________________________________________________________________________________________________________
    
  render(){
    const { color, fontColor } = this.props.anyProps 
    const { hover } = this.state 
    const button_style = {              // <button> ソートボタン
      height:25,
      width:"100%",
      backgroundColor: hover ? color : "#fff",
      color :  hover ? fontColor : null ,
      border:"none",
      borderRadius:3,
      textAlign:"left",
      marginBottom:8,
      cursor:"pointer",
      transition:"0.2s",
    }

    return(
      <button 
        style        = { button_style } 
        onClick      = {  this.props.onClick }
        onMouseOver  = { ()=> this.setState({ hover : true  }) }
        onMouseLeave = { ()=> this.setState({ hover : false }) }
        onFocus      = { ()=> this.setState({ hover : true  }) }
        onBlur       = { ()=> this.setState({ hover : false }) }
      >
        { this.props.label }
      </button>
    )
  }  
}

