  import {LOGIN, LOGOUT,LOGINEDIT,LOGSTATUS,ERR,SEARCH_ERR,INPUT_ERR,FATALERR,SESSIONERR,MENU, PAGEMOVE, CLEARERR} from '../../comRedux/ActionConst';
  
  export const key = 'COMMON';

  const initLogin = {
    userCode:'',
    userName:'',
    password:'',
    sSessionId:'',
    lang:'',
    entryType:0, // entryType 0:loginPage 1:Menu
    isChanged:false,
    errMsg:'',
    selectMenuId : 'TEST',
    selectMenuName :'MENU_NAME（）',
    purcCode:'',
    purcName:'',
    compCode:'',
  }

  const initMenu = {
    didset : false,
    menuList : [],
  }
  
  const initCommon = {
    searchErrMsg:'',
    inputErrMsg:'',
    fatalErrMsg:'',
    currentPage: 1 ,
    mode : '0',// 0初期値　1追加 2編集 3削除 4複写
  }
  
  export const initialState = {
    //Loginデータの保管
    Login:JSON.parse(JSON.stringify(initLogin)),
    //Menu情報の保管
    Menu:JSON.parse(JSON.stringify(initMenu)),
    //共通パラメーターの保管
    Common:JSON.parse(JSON.stringify(initCommon)),
  }

  export default (state = initialState, action) => {
      switch (action.type) {
        case LOGINEDIT:
          for( let obj in action.payload){
              state.Login[obj] = action.payload[obj];
          }
          return Object.assign({}, state,{Login:{...state.Login}});

        case LOGIN:
          return  Object.assign({}, state, { 
            Login:{
              ...state.Login,
              sSessionId:action.payload.sSessionId,
              password:'',//password clear
              lang:action.payload.lang||'',
              entryType: 1,
              isChanged:false,
              errMsg:'',//errMsg clear
              purcCode:action.payload.purcCode,
              compCode:action.payload.compCode,
            }
        });
        case LOGOUT:
          return Object.assign({}, state, {
            Login:{
              ...state.Login, 
              userCode:'',
              userName:'',
              //password:'',
              lang:'',
              entryType: 0,
              isChanged:false,
            },
            Menu:{},
            Common:{},
        });
        case MENU:
          return Object.assign({},state,{
            Menu:{
              didSet:true,
              menuList:action.menuList
            }
          });
        //機能内画面推移
        case PAGEMOVE:
          return Object.assign({},state,{
            Common:{
              ...state.Common,
              currentPage:action.payload.currentPage,
              mode:action.payload.mode,
            }
          });
        
        case ERR:
          return Object.assign({}, state, {
            Login:{
              ...state.Login,
              errMsg:action.errMsg,
            }
          });
        case SEARCH_ERR :
          return Object.assign({}, state,{
            Common:{
              ...state.Common,
              searchErrMsg:action.errMsg,
            }
          });
        case INPUT_ERR :
          return Object.assign({}, state,{
            Common:{
              ...state.Common,
              inputErrMsg:action.errMsg,
            }
          });
        case FATALERR :
          return Object.assign({}, state , {
            Common:{
              ...state.Common,
              currentPage:9,
              fatalErrMsg:action.errMsg,
            }
          });
        case SESSIONERR :
          return Object.assign({}, state , {
            Common:{
              ...state.Common,
              currentPage:0,
            }
          });
        case CLEARERR :
          return Object.assign({}, state , {
            Common:{
              ...state.Common,
              inputErrMsg:'',
              searchErrMsg:'',
            }
          });
        default : 
        if(action.type === '@@router/LOCATION_CHANGE'){
          return Object.assign({},state,{
              Common:JSON.parse(JSON.stringify(initCommon)),
          });
        }else{
          return state;
        }
      }
  }