import React from 'react';

export default class Low extends React.Component{
  render(){
    
    const style={
        display:"flex",
        flexDirection:"row",
        
        alignItems:
          this.props.top      ? "flex-start" : 
          this.props.bottom   ? "flex-end" : 
          this.props.middle   ? "center":
          "flex-end",
        
        justifyContent:
          this.props.center   ? "center" :
          this.props.between  ? "space-between": 
          this.props.end      ? "flex-end" :
          null,
        
        flexWrap:
          this.props.noWrap     ? null :
          "wrap",

        width:this.props.w,
        maxWidth:this.props.maxW,
        padding:this.props.padding,
        margin:this.props.margin,
        
    }

    return(
      <div style={Object.assign({},style,this.props.style)}
        className={this.props.className}
      >
        {this.props.children}
      </div>
    );
  }
}