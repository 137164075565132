/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Fragment } from 'react';
import {Link} from 'react-router-dom'


// ////////////////////////////////////////////////////////////////////////
export default class SideMenu extends React.Component{
  constructor(props){
    super(props);
    this.state={
      toggle        : true,
      selectedMenu  : null,
      menuNoS       : 0,　  //　表示中の一番上のメニュー
      menuNoE       :       //　表示中の一番下のメニュー
        this.props.menu.length < 15 ? this.props.menu.length :　//「全体のメニュー数」が 15 未満なら「全体のメニュー数」を初期値に設定
        15,       
      }
  }
  // ___________________________________________________________________________________________________
  
  sideOnWheel =(e)=>{
    const { menuNoS,menuNoE} = this.state
    const { menu } = this.props

    if( e.deltaY > 0 && menuNoE < menu.length){   // 「下にホイール」かつ「表示中の一番下のメニュー」が「全体の一番最後のメニュー」以外なら
      this.setState({ 
        menuNoS: menuNoS + 1, 
        menuNoE: menuNoE + 1 
      })
    }else if( e.deltaY <= 0 && menuNoS > 0 )(   // 「上にホイール」かつ「表示中の一番上のメニュー」が「全体の一番最初のメニュー」以外なら
      this.setState({ 
        menuNoS: menuNoS - 1, 
        menuNoE: menuNoE - 1 
      })
    )
  }
  // ___________________________________________________________________________________________________

  menuOnKeyDown =( i, e )=>{
    const { menuNoE,menuNoS } = this.state
    const { menu } = this.props

    if( e.keyCode === 40 ){       // 方向キー　↓　を押した時
      if( i < menu.length -1 ){  // 「フォーカス中のメニュー」が「全体の一番最後のメニュー」以外なら
        if( i == menuNoE - 1 ){   // 「フォーカス中のメニュー」が「表示中の一番下のメニュー」なら  
          this.setState({ 
            menuNoS: menuNoS + 1, 
            menuNoE: menuNoE + 1, 
          })
        }else{
          e.target.nextElementSibling.focus();  // フォーカスを次の要素へ移動
        }
      }
    }else if( e.keyCode === 38 ){　// 方向キー　↑　を押した時
      if( i > 0 ){                // 「フォーカス中のメニュー」が「全体の一番最初のメニュー」以外なら
        if( i == menuNoS ){       // 「フォーカス中のメニュー」が「表示中の一番上のメニュー」なら  
          this.setState({ 
            menuNoS: menuNoS - 1, 
            menuNoE: menuNoE - 1, 
          })
        }else{
          e.target.previousElementSibling.focus();  // フォーカスを前の要素へ移動
        }
      }
    }      
  }
  // ___________________________________________________________________________________________________

  render(){

    const { 
      menuNoS,
      menuNoE,
      toggle,
      selectedMenu 
    } = this.state
    const { menu,space } = this.props

    //＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿　スタイル記述欄　ここから　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿
    
    const contents_wrap = { // <div>
      height:"100%",
    }

    const side_wrap = {  // <div>
      paddingTop:"50px",
      position:"fixed",
      display:"flex",
      height:"100vh",
      flexDirection:"column",
      background:"#747",
      width:toggle? "220px" : "50px",
      transition:"width 0.2s",
      boxShadow: "2px 0px 2px 0 rgba(0,0,0,.23)",
      zIndex:100,
    }

    const menu_icon = { // <div>
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      minWidth:"50px",
    }
    const menu_title = { // <div>
      paddingLeft:
        toggle ? 16 :
        null,
    }

    const mainpage={ // <div>
      paddingTop:"50px",
      paddingLeft:toggle? "220px" : "50px",
      transition:" padding-left 0.2s",
      height:"100%",
    }
    //________________________________________________________________________________

    let menuArr = [];
    
    for( let i = menuNoS ; i < menuNoE ; i++ ){
      menuArr.push(
        <Menu
          onClick       = { ()=> this.setState({ selectedMenu  : i }) } 
          onKeyDown     = { (e)=> this.menuOnKeyDown( i, e ) }
          key           = { "sidemenu"+ i }
          to            = { menu[i].link }
          label         = { !toggle && menu[i].title }
          space         = { space }
          selected      = { selectedMenu == i ? true : false }
        >
          {toggle ?
          <div style={menu_title}>{menu[i].title}</div>
          :
          <div style={menu_icon}>{menu[i].icon}</div>
          }
        </Menu>
      )
    }
    //_____________________________________________________________________________

    return(
      <div style={contents_wrap}>
        <div 
          style        = { side_wrap } 
          onWheel      = { this.sideOnWheel }
        >
          <Hamburger onClick = { ()=> this.setState({toggle:!toggle}) } toggle={ toggle }/>
          {menuArr}
        </div>
          
        {/* メインメニュー */}
        <div style={mainpage}>
          {this.props.children}
        </div>

      </div>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Menu extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover:false,
    }
  }

  render(){

    const { hover } = this.state
    const { 
      space,
      to,
      onClick,
      onKeyDown,
      children,
      label,
      selected,
    } = this.props

    //＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿　スタイル記述欄　ここから　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿

    const link_style = {
      position:"relative",
      alignItems:"center",
      cursor:"pointer",
      width :"100%", 
      display:"flex",
      minHeight: space,
      whiteSpace:"nowrap",
      color:
        selected? "#747" :
        hover? "#747" :
        "#FFF",
      backgroundColor:
        selected? "#FFF" :
        hover? "#FFF" :
        null,
      fontWeight:"bold",
      textDecoration:"none",
      transition:'0.2s',
    }
    
    const balloon_wrap = {                             // <div> 吹き出しのWrap
      position:"absolute",
      left:"100%",
      display:"flex",
      alignItems:"center",
      whiteSpace:"nowrap",
      zIndex:2,
      height:0,
      width:0,
      paddingLeft:4,        
    }
    const balloon_parts = {                            // <div> 吹き出し上部の三角形
      borderRight: "8px solid  #303",
      borderBottom: "5px solid transparent",
      borderTop: "5px solid transparent",
    }

    const balloon_title = {                             // <div> 吹き出しの言葉
      padding:"8px 16px",
      backgroundColor:"#303",
      borderRadius:5,
      fontSize:14,
      color:"#fff",
      display:"flex",
      alignItems:"center",
      boxShadow: "3px 3px 3px #ddd",

    }
    //　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿

    return(   
      <Link 
        onClick       = { onClick } 
        onKeyDown     = { onKeyDown }
        onMouseOver   = { ()=> this.setState({ hover : true }) } 
        onMouseLeave  = { ()=> this.setState({ hover : false }) } 
        onFocus       = { ()=> this.setState({ hover : true }) } 
        onBlur        = { ()=> this.setState({ hover : false }) } 
        style         = { link_style }
        to            = { to }
      >
        { children }
      {/* ※１　親で label の引数が設定されていて、かつマウスがアイコン上にある時にレンダーします */}
      { label && hover && 
        <div style={balloon_wrap}>
          <div style={balloon_parts}></div>
          <div style={balloon_title}>
            { label }
          </div>
        </div>
      }   
      {/* _______________________________________________________________________________________*/}
      </Link>
    );
  }
}

///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Hamburger extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hover : false,
    }
  }
  //　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿


  render(){
    const { hover } = this.state
    const { onClick, toggle } = this.props



    const toggle_style ={
      display:"flex",
      minHeight:"50px",
      height:"50px",
      paddingRight:"16px",
      fontWeight:"bolder",
      justifyContent:"flex-end",
      cursor:"pointer",
      fontSize:"1.3rem",
      backgroundColor:"transparent",
      border:"none",
      alignItems:"center",
      // width:"200px",
    }

    const hamburger = {
      display:"inline-flex",
      position:"relative",
      height:24,
      width:24,
      transition:"4s"
    }

    const ham1 = {
      position:"absolute",
      height:4,
      backgroundColor:"#FFF",  
      borderRadius:"30px",
      transition:"0.2s",
      width: hover? "50%": "100%",
      top:hover?  5 : 0,
      left: hover ? toggle? 0 : "" : "",
      right: hover ? toggle? "" : 0 : "",
      transform: hover ? toggle? "rotate(-45deg)": "rotate(45deg)" : "",
    }

    const ham2 = {
      position:"absolute",
      height:4,
      borderRadius:"30px",
      backgroundColor:"#FFF", 
      width:"100%",
      top:10,
    }

    const ham3 = {
      position:"absolute",
      height:4,
      borderRadius:"30px",
      backgroundColor:"#FFF",  
      transition:"0.2s",
      width: hover? "50%": "100%",
      bottom:hover?  5 : 0,
      left: hover ? toggle? 0 : "" : "",
      right: hover ? toggle? "" : 0 : "",
      transform: hover? toggle? "rotate(45deg)": "rotate(-45deg)" :"",
    }
    //　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿

    return(
      <button 
        style         = { toggle_style  }
        onClick       = { ()=> onClick() }
        onMouseOver   = { ()=> this.setState({hover :true})  }
        onMouseLeave  = { ()=> this.setState({hover :false}) }
        onFocus       = { ()=> this.setState({hover :true})  }
        onBlur        = { ()=> this.setState({hover :false}) }
      >
        <a style={hamburger} >
          <span style={ham1}></span>
          <span style={ham2}></span>
          <span style={ham3}></span>
        </a>
      </button>
    )
  }

}