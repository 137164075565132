/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import React,{Fragment} from 'react';

export default class Icon extends React.Component{
  constructor(props){
    super(props);
    this.state={
      onmouse:false,
    }
  }

  onMouseOver =()=>{
    this.setState({onmouse:true});
    
  }

  onMouseLeave =()=>{
    this.setState({onmouse:false});
  }

  render(){

    ///////  スタイル記述欄　ここから
    const wrap = {                             // <div> アイコンのwrap 
      position:"relative",
      display:"inline-flex",
      justifyContent:"center",
      flexDirection:"column",
      alignItems:"center",
    }
    
    const iconStyle = {                               // <button> アイコン外枠のボタン
      backgroundColor:"transparent", 
      border:"none", 
      cursor:"pointer",
      // outline:"none",
    }

    const balloon_wrap = {                             // <div> 吹き出しのWrap
      position:"absolute",
      top:"100%",
      display:"flex",
      flexDirection:"column",
      alignItems:"center",
      whiteSpace:"nowrap",
      zIndex:2,
      height:0,
      width:0,
    }
    const balloon_parts = {                            // <div> 吹き出し上部の三角形
      borderRight: "10px solid transparent",
      borderBottom: "10px solid #000",
      borderLeft: "10px solid transparent",
    }
    const balloon_title = {                             // <div> 吹き出しの言葉
        padding:8,
        backgroundColor:"black",
        borderRadius:5,
        fontSize:12,
        color:"#fff",
    }
    //　＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿＿

    return(   
      <div style={wrap}>
        <a tabIndex="0" style={ wrap }>
          <img
            src={this.props.src}
            width ={this.props.w ? this.props.w : 25} // デフォルトで25pxを設定
            height={this.props.h ? this.props.h : 25} 
            className={this.props.className}
            style={Object.assign({},iconStyle,this.props.style)} 
            onClick={this.props.onClick}
            onMouseOver={this.onMouseOver}
            onMouseLeave={this.onMouseLeave}
          />
        </a>

        {/* ※１　親で label の引数が設定されていて、かつマウスがアイコン上にある時にレンダーします */}
        {this.props.label && this.state.onmouse && 
        <div style={balloon_wrap}>
          <div style={balloon_parts}></div>
          <div style={balloon_title}>
            {this.props.label}
          </div>
        </div>
        }   
        {/* _______________________________________________________________________________________*/}
      </div>
    );
  }
}