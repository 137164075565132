import React, { Fragment } from "react";
import '../css/ediLoading.css';

export default class EDILoading extends React.Component{

  render(){
    // const spinner_type = {
    //   position:'fixed',
    //   textAlign: 'center',
    //   fontSize: '40px',
    //   zIndex:999,
    //   left: '0px',
    //   top: '0px',
    //   right: '0px',
    //   bottom: '0px',
    //   width: '100%',
    //   height: '100%',
    //   backgroundColor:'rgba(255,255,255,0.3)',
    // }


    return (
      <Fragment>
        <div className="spinner_edi_loading" style={this.props.style}>
          <div className="rect1"></div>
          <div className="rect2"></div>
          <div className="rect3"></div>
          <div className="rect4"></div>
          <div className="rect5"></div>
          <div className="rect6"></div>

        </div>
      </Fragment>
    )
  }
}