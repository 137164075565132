//Action定義は全部大文字
export const ADD = 'ADD';       //  追加
export const DELETE = 'DEL';    //  削除
export const EDIT = 'EDIT';     //  修正
export const SEARCH = 'SEARCH'; //  検索
export const COPY = 'COPY';     //  複写
export const PAGEMOVE = 'PAGEMOVE';    //currentPageの移動
export const SHOW_SEARCHLIST = 'SHOW';
export const OPENSEARCHAREA = 'OPENSEARCHAREA';
export const CHECK = 'CHECK';
export const INITINPUT = 'INITINPUT';
export const BACK = 'BACK';     // 戻る
export const SEARCHEDIT = 'SEARCHEDIT';
export const FORMEDIT = 'FORMEDIT';
export const LOADSEARCHLIST = 'LOADSEARCHLIST';
export const LOADINPUT = 'LOADINPUT';
export const LOADCOMBO = 'LOADCOMBO';
export const SUBMIT = 'SUBMIT';
export const TABLESTATE = 'TABLESTATE';
export const PAGE = 'PAGE';
export const LOGIN = 'LOGIN';
export const LOGINEDIT = 'LOGINEDIT';

export const MENU = 'MENU';
export const LOGOUT = 'LOGOUT';
export const CLEARSTORE = 'CLEARSTORE';
export const EXCEL = 'EXCEL';
export const PDF = 'PDF';
export const CSV = 'CSV';

export const CHECKON = "CHECKON"; // 確認画面
export const CHECKOFF = "CHECKOFF";// 確認画面から入力画面に戻る

export const ERR = 'ERR'; // エラー
export const SEARCH_ERR = 'SEARCH_ERR'; // エラー
export const INPUT_ERR = 'INPUT_ERR'; // エラー
export const FATALERR = 'FATALERR'; // エラー
export const SESSIONERR = 'SESSIONERR';//エラー
export const CLEARERR = 'CLEARERR';//エラーメッセージをクリア
export const SETDIDSET = 'SETDIDSET';

export const CLEARSEARCHLIST = 'CLEARSEARCHLIST';//再検索時SearchListValuesのクリア
export const GETNAME = 'GETNAME';//コードから名称を取得

export const GET_REDUCER_DATA = 'GET_REDUCER_DATA';//他のReducerのデータを共有できるAction