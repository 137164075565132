import React, { Fragment } from 'react';
import ErrorMsgBar from './ErrorMsgBar';

export default class InputBtnArea extends React.Component{
  render(){
    const area_style = {
      // backgroundColor:"#f9f9f3", 
      padding:"8px 16px 8px 16px",
      display:"flex",
    }
    const error_style = {
      backgroundColor:"#e00",
      color:"#fff",
      padding:"8px 16px",
    }

    return(
      <Fragment>
        {/* { this.props.errMsg && 
        <div style={ error_style }>{ this.props.errMsg }</div>  
        } */}
        <ErrorMsgBar currentPage = {2} />
        <div style={ area_style }>
          {this.props.children}
        </div>
      </Fragment>
    )
  }
}