import React, { Fragment } from 'react';

export default class EDIHideable extends React.Component{
  constructor(props){
    super(props);
    this.state={
      hidden:this.props.hidden || false,
      height:null,
    }
    this.heightDefault = null;
    this.processing = false;
  }
  // ____________________________________________________________________
  componentDidMount(){
    this.setState({height:this.wrapRef.getBoundingClientRect().height})
    this.hiddenAction(this.props.hidden);
  }
  //SearchErrがある場合、自動隠ししません
  //EDIのみの仕様
  shouldComponentUpdate(nextProps,nextStates){
    if(nextProps.hidden !== this.state.hidden){
       this.heightDefault=this.wrapRef.getBoundingClientRect().height
       this.setState({
         hidden:nextProps.hidden,
         height:nextProps.hidden?0:this.heightDefault
        })
       this.hiddenAction(nextProps.hidden);
       return true;
     }
     return true;
  }

  hiddenAction=(hidden)=>{                               // ボタンをクリックしたとき
    if(!hidden){                     // -中身が隠されているとき
      setTimeout(()=>{                        // --中身がすべて見えてから処理を行うように、時間差で処理を行う。
        this.processing = false;              // 処理が終わったことを明示しています。（ボックスが閉じている途中にクリックしたらスタイルが崩れるので）
      },this.props.speed? this.props.speed * 1000 : 500)　            // --speed を親から指定できるように
    }else{                                    　// -中身が見えているとき　(閉じるアクション開始)
      if( this.processing === true ) { return; }       　//  閉じるアクションが働いている時は処理をはじきます
      setTimeout(()=>{                        // --中身がすべて見えてから処理を行うように、時間差で処理を行う。
        this.processing  = true;              // 処理が終わったことを明示しています。（ボックスが閉じている途中にクリックしたらスタイルが崩れるので）
      },this.props.speed? this.props.speed * 1000 : 500)　            // --speed を親から指定できるように
    }
  }

  render(){
    const hidden = {
      // transform:'translate('+this.state.height+')',
      // transition:'.5s',
      transition:this.props.speed?  "all "+ this.props.speed + "s ease-in-out" : "all 0.5s ease-in-out",    // speed を親から指定できるように
      height: this.state.hidden? 0:this.state.height,
      overflow: "hidden" ,
      position:"relative",
      //opacity:this.state.hidden? 0:null,
    }

    return(
      <Fragment>
      <div style={ hidden }>
        <div  
          ref       = { (e)=> this.wrapRef = e}
          style     = { this.props.style }
          className = { this.props.className }
        >
          {this.props.children}
        </div>
      </div>
      </Fragment>
    )
  }
}