
import React, { Fragment,Component } from 'react';
import SideMenu from '../../component/SideMenu';
import { ConnectedRouter } from 'connected-react-router';
import { connect } from 'react-redux';
import { history } from '../../comRedux/createStore';
import {logOut,menu,logStatus} from '../../comRedux/Actions';
import RouterContainer from './RouterContainer';
import * as Sky from "../../EDIComponent/EDIComponent";
import {axiosPost,server} from '../../comRedux/axiosPost';
import { push } from 'connected-react-router'　//Logout用

 class ContainedButtons extends Component {
  constructor (props) {
    super(props);
  }
  // 画面ロード時
  async componentDidMount() {
    if (!this.props.didSet) {
      var returnJson = {
      };
      axiosPost.post(server+'/menu/search?rev='+Math.random(),returnJson)
      .then((res) => {
        if (res.data!=null) {
          this.props.dispatch(menu(res.data));
        }
      })
      .catch(err => {
        alert(err);
        console.log('JSON読み込みエラー')
          return
      })
    }
  }
  handleLogOut = async(e) =>{
    e.preventDefault();
    e.stopPropagation();
    const result = await Sky.confirm({ message: "LogOutしますか?" });
    if( result ){
      this.props.dispatch(push('/',{showPrompt:false}))
      this.props.dispatch(logOut());
    }
  }

  select_menu = (menuId) =>{
    var returnJson = {
      menuId:menuId
   };
    //e.preventDefault();
    axiosPost.post(server+'/menu/select',returnJson)
    .then((res) => {
      if (res.data==true) {
        //this.props.dispatch(login());
      } else {
        //this.props.dispatch(error('LOGIN','ログインできません'));
      }
      //return res.data;
    })
    .catch(err => {
      alert(err);
      console.log('JSON読み込みエラー')
        return
    })
    
  }

  render(){
    // JSONデータの読み込みが完了しているか？
    if(!this.props.didSet) {
      return <Sky.Loading style={{margin:'auto',}}/>
    }
  return (
      <Fragment>
        <ConnectedRouter history={history}>
        <Sky.EDIHeader title="EDI-System">
          <Sky.LogoutBtn label="ログアウト"  onClick={this.handleLogOut} />
          {/* <Icon src={logout} label="ログアウト" className="header-icon"/> */}
        </Sky.EDIHeader>
        <SideMenu space="50px" menu={ this.props.menuList}>
          <RouterContainer/>
        </SideMenu>
        </ConnectedRouter>
      </Fragment>
    );
  }
}
const mapStateToProps = (state,ownPropS) =>{
  return {
    //stateの範囲を縮めるとRender回数は減ります
    ...state.COMMON.Menu
  }
}
export default connect(mapStateToProps)(ContainedButtons);
