import request from 'axios';
import {store} from '../index';
import { setCookie } from '../util/cookie'

export const axiosPost = request.create({
    xsrfHeaderName: 'X-CSRF-Token',
    withCredentials: true
})
axiosPost.interceptors.request.use(
    config => {
        //Java側にCompCodeとSessioonIdを渡す
        setCookie('compCode',store.getState()['COMMON']['Login'].compCode || '01',1000*60*60);
        setCookie('sSessionId',store.getState()['COMMON']['Login'].sSessionId || null,1000*60*60);
        // 全てのRequestに必須Paraを追加する場合、下記のよう
        //   let defaultParams = {
        //     compCode: store.getState()['COMMON']['Login'].compCode
        //   };
        //   config.data = Object.assign(defaultParams, config.data);
    　  // headerのカスタマイズ
        //   config.headers['B-Token'] = store.state.token;
      return config;
    }, function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    });


//もっと楽の方法で対応する
export const server = process.env.NODE_ENV === 'development' ? 'http://localhost:8080' : window.PROJECT_URL;