import React, {Fragment} from 'react';
import * as Sky from "./EDIComponent";

export default class CalendarComponent extends React.Component{
  constructor(props){
    super(props);
    const {
      month,
      year,
      yearMonth,
      breakPoint,
      setData,
    } = this.props;
    this.state = {
      date          : new Date().getDate(),         // 今日の日付 
      month         : month || yearMonth.split('/')[1] ||new Date().getMonth()+1,     // 表示中の月
      year          : year || yearMonth.split('/')[0] ||new Date().getFullYear(),     // 表示中の年
      breakPoint    : breakPoint || 6,                      // 行の最後にくる曜日。（折り返し地点）
      setData       : setData || {},
    }
    this.dateWrap_style = {
      display:"flex",
      flexDirection : "column",
      width : "100%",
    }
    this.dateRow_style = {
      display:"flex",
      width : "100%",
    }

    this.year  = new Date().getFullYear() ;                       // 年を抽出
    this.month = ("0" + ( new Date().getMonth()+1) ).slice(-2);   // 月をMM方式で抽出（ ("0" + " ").slice(-2) とすることで頭に０をつけることができる　）
    this.date  = ("0" + new Date().getDate() ).slice(-2);     // 現在選択中の日をDD方式で抽出（ ("0"+" ").slice(-2) とすることで頭に０をつけることができる　）

    this.today  = this.year +"/"+ this.month +"/"+ this.date      // 今日の日付を定義
  }
  //________________________________________________________________________________________________
  
  // 次の月へ移動するボタンを押した時
  handleOnNextMonth = () => {
    if(this.props.handleOnClickPreOrNext){
      this.props.handleOnClickPreOrNext('next');
      return;
    }
    const { year, month } = this.state;
    if (month < 12) {                              // 表示月が１２より小さい場合
      this.setState({ month : month + 1 })         // 表示月を＋１します
    }else{                  // それ以外なら(12月なら)
      this.setState({
        year  : year + 1,  // 表示年を＋１して
        month : 1,         // 表示月を１月にします  
      });                                        
    }
  }
  //________________________________________________________________________________________________
  
  // 前の月へ移動するボタンを押した時
  handleOnPreviousMonth = () => {
    if(this.props.handleOnClickPreOrNext){
      this.props.handleOnClickPreOrNext('pre');
      return;
    }
    const { year, month } = this.state;
    if (month > 1) {                               // 表示月が１より大きい場合
      this.setState({ month : month - 1 })         // 表示月を－１します
    }else{                  // それ以外なら（1月なら）
      this.setState({
        year  : year - 1,  // 表示年を－１して
        month : 12,        // 表示月を１２月にします
      })              
      this.setState({})                    
    }
  }
  //________________________________________________________________________________________________

  // 日付の値が変わった時
  handleOnChangeDateValue = (date,value) => {
    const { onChange } = this.props;
    let setData_copy = this.state.setData; // 配列をコピー
    setData_copy[date] = value;
    this.setState({ setData: setData_copy });  // コピーした配列をセット
    onChange && onChange(setData_copy);
  }
  //________________________________________________________________________________________________

  // 日付をクリックしたとき
  handleOnClickDate = (date) => {
    const { onChange } = this.props;
    let setData_copy = this.state.setData; // 配列をコピー
    setData_copy[date] = setData_copy[date] ? false : true ;
    this.setState({ setData: setData_copy });  // コピーした配列をセット
    onChange && onChange(setData_copy);
  }
  //________________________________________________________________________________________________
  // データ更新用
  updateEvent = () =>{
    this.props.updateEvent && this.props.updateEvent(this.state.setData);
  }

  render(){
    const { 
      lang,
      readOnly,
    } = this.props

    const {
      year,
      breakPoint,     // 週の最後に来る曜日を指定する変数【０～６ : 日～土】
      month,
      setData,
    } = this.state

    // _________________________________________________________________________________________________________________

    // 使用変数の宣言

    let date_arr         = [];                             // 日付を入れていくための配列
    const dateRow_arr    = [];
    // _____________________________________________________________________________________________________________
    
    // ________________________________________________________________________________________________________________

    // 　先月の残りの日付けを作成

    const firstDayNo  = new Date(year, month - 1, 1).getDay();         // 当月の最初の曜日番号　※getDay()で取得できるのは、0~6の番号のため
    // console.log( new Date(year, month - 1, 0) )
    let   startDayCount = null;                                       // カレンダーの先頭に来る前月の日付けを作成するために使用するカウント変数

    if (breakPoint === 6){ startDayCount = 0 }                      // breakpointの次の曜日から週が始まるので、＋１をしています。
    else    { startDayCount = breakPoint + 1 }                      // ただしbreakpointが６（土曜日）の場合、７がないので０（日曜日）に戻します。
        
    for(let i = 0 ; i < 6 ; i++){
      
      if(startDayCount === firstDayNo) { break }                    // もしカウントが当月の1日の曜日に達したら日付作成完了です。
      else if (startDayCount === 6)    { startDayCount = 0 }        // また、もしカウントが6の場合、次は0になります。
      else                             { startDayCount ++  }        // 当月の1日に達するまでカウントしていきます

      let YYYY = null
      let MM   = null
      if( month === 1 ){  // 1月の場合 
        YYYY  = year - 1
        MM    = 12        
      }else{
        YYYY  = year
        MM  = ("0" + ( month - 1 ) ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      }
      const DD = new Date(year , month - 1, -i).getDate()                 // forループに基づいて作成される前月の日付け
      
      const madeDate  = YYYY + "/" + MM + "/" + DD

      date_arr.unshift(                                             // unshift()で<button>日付けを配列の手前に順に追加していきます。
        <DateComponent  
          notThisMonth
          readOnly     = { readOnly }  
          key          = { madeDate }
          today        = { madeDate === this.today}
          label        = { DD }
          value        = {setData[madeDate]}
          onClick      = {()=>this.handleOnClickDate(madeDate)}
          onKeyDown    = {()=>this.handleOnClickDate(madeDate)}
          onChange     = {(value)=>this.handleOnChangeDateValue(madeDate,value)}
        />
      )

    }
    // ________________________________________________________________________________________________________________

    //  当月の日付けを作成   
    
    const lastDate = new Date(year , month, 0).getDate();          // 当月の最終日

    for(let i = 1; i <= lastDate; i++){
      const MM          = ("0" + month ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      const DD          = ("0" + i ).slice(-2)
      const madeDate    = year + "/" + MM + "/" + DD
      let dayNo         = new Date( madeDate ).getDay();                           // 曜日を取得。dayNo=０なら日曜日、dayNo=６なら土曜日となってます。

      date_arr.push(                                                  // push()で当月の日付<button>を配列の後続に入れていきます
        <DateComponent
          readOnly     = { readOnly }  
          key          = { madeDate }
          today        = { madeDate === this.today }
          dayNo        = { dayNo }
          label        = { i }
          value        = {setData[madeDate]}
          onClick      = {()=>this.handleOnClickDate(madeDate)}
          onKeyDown    = {()=>this.handleOnClickDate(madeDate)}
          onChange     = {(value)=>this.handleOnChangeDateValue(madeDate,value)}
        />
      )
      if(dayNo === breakPoint) { 
        dateRow_arr.push(<div style={this.dateRow_style} key={"dateRow" + i}>{date_arr}</div>)                // 最後にbreakPoint変数で選択した曜日の後に改行を入れる
        date_arr = [];
      }
    }
    // ________________________________________________________________________________________________________________

    //  当月のカレンダーの末尾に来る、来月の頭の日付けを作成    

    let endDayCount = new Date(year , month, 0).getDay()          // 月末の曜日Noを取得して、カウント変数にセット

    for(let i = 1; i <= 6; i++){

      if( endDayCount === breakPoint ) { break }                      // もしカウントがカレンダーの最後の曜日に達したら日付作成完了です  
      else if( endDayCount === 6 )     { endDayCount = 0 }            // また、もしカウントが6の場合、次は0になります。
      else                             { endDayCount ++  }            // 最後の曜日に達するまでカウントしていきます

      let YYYY = null
      let MM   = null
      if( month === 12 ){  // 1月の場合 
        YYYY  = year + 1
        MM    = '01'        
      }else{
        YYYY  = year
        MM  = ("0" + ( month + 1 ) ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。
      }
      const D = new Date(year , month, i).getDate()                 // forループに基づいて作成される次月の日付け
      const DD  = ("0" + D ).slice(-2);                    //("0" + "" ).slice(-2) は0を頭につけて二桁表示するためです。

      const madeDate  = YYYY + "/" + MM + "/" + DD

      date_arr.push(
        <DateComponent  
          notThisMonth
          readOnly     = { readOnly }  
          key          = { madeDate }
          today        = { madeDate === this.today}
          label        = { D }
          value        = {setData[madeDate]}
          onClick      = {()=>this.handleOnClickDate(madeDate)}
          onKeyDown    = {()=>this.handleOnClickDate(madeDate)}
          onChange     = {(value)=>this.handleOnChangeDateValue(madeDate,value)}
        />
      )
    }
    dateRow_arr.push(<div style={this.dateRow_style} key={"dateRowLast"}>{date_arr}</div>)                 // 最後にbreakPoint変数で選択した曜日の後に改行を入れる


    return(
      
      <div style={this.dateWrap_style}>
      <Header 
        readOnly              = {readOnly}        
        lang                  = {lang} 
        month                 = {month} 
        year                  = {year}
        onClickPreviousMonth  = {this.handleOnPreviousMonth}
        onClickNextMonth      = {this.handleOnNextMonth}
        updateEvent           = {this.updateEvent} //データ更新用
      />
      <Week lang={lang} breakPoint={breakPoint}/>
      {dateRow_arr}
      </div>
    )
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class DateComponent extends React.Component{
  constructor(props){
    super(props);
    const { value } = this.props;
    this.state = {
      hover    : false,
      editable : false,
      value    : value || "",
    }
    this.date_style = {
      fontSize:25,
      padding : 4,
    }
    this.dateValue_style = {
      height : "100%",
      display:"flex",
      alignItems:"center",
      justifyContent : "center",
      fontSize:16,
      padding : 4,
      maxWidth : "100%",
      
    }
    this.wrap_style = {              // <button> リストアイテム
      display:"flex",
      flexDirection : "column",
      minWidth:"14.285%",
      outline: "none",
      height:'10vh',
      border:"1px solid #CCC",
      cursor:"pointer",
      boxSizing:"border-box",
      transition:"0.2s",
    }
    this.textarea_style = {
      height : "100%",
      width : "100%",
      resize: "none",
      fontSize:16,
    }
    this.trueValue_style = {
      height : 30,
      width : 30,
      border : "5px solid rgb(85, 109, 174)",
      // backgroundColor:"",
      borderRadius :"100%",
    }
  }
  // ________________________________________________________________________________________________________________
  
  textOnBlur = () => {
    const { onChange } = this.props;
    const { value } = this.state;
    onChange && onChange(value);
    this.setState({ editable : false });
  }

  handleOnKeyDown = (e) => {
    const { onKeyDown } = this.props;
    if(e.keyCode === 13){ onKeyDown && onKeyDown() }
  }
  // ________________________________________________________________________________________________________________

  render(){
    const { hover, editable, value } = this.state 
    const { 
      onClick,
      label,
      style,
      today,
      dayNo,
      notThisMonth,
      readOnly,
    } = this.props;

    Object.assign(this.wrap_style,{              // <button> リストアイテム
      color:
        hover         ? null   : //オンマウス中の日付け
        today         ? "#fff" : //今日の日付け
        notThisMonth  ? "#aaa" : //今月じゃない日付け
        dayNo === 6   ? "#00f" : //土曜日
        dayNo === 0   ? "#f00" : //日曜日
        null,
      backgroundColor:
        hover    ? "#ccc"    : //オンマウス中の日付け
        today    ? "#bbf"    : //今日の日付け
        "transparent",
      pointerEvents: 
        (notThisMonth||readOnly)? "none" : 
        null,
    });

    let viewValue;
    if(editable) {
      viewValue = 
        <textarea 
          style    = {this.textarea_style} 
          value    = {value} 
          autoFocus
          onChange = {(e)=>this.setState({ value : e.target.value})}
          onBlur   = {this.textOnBlur}
        />
    }else if(typeof(this.props.value) !== 'boolean') {
      viewValue = 
        <div style={this.dateValue_style}>
        {this.props.value}
        </div>
    }else if(this.props.value) {
      viewValue = 
        <div style={this.dateValue_style}>
        <div style={this.trueValue_style}></div>
        </div>
    }else {
      viewValue = <div style={this.dateValue_style} />
    }

    return(
      <div
        tabIndex      = "0"
        style         = { Object.assign({}, this.wrap_style, style ) } 
        onClick       = { onClick }
        onKeyDown     = { this.handleOnKeyDown}
        onMouseOver   = { ()=> this.setState({ hover : true  }) }
        onMouseLeave  = { ()=> this.setState({ hover : false }) }
        onFocus       = { ()=> this.setState({ hover : true  }) }
        onBlur        = { ()=> this.setState({ hover : false }) }
        onDoubleClick = { ()=> this.setState({ editable : true })}
      >
        <div style={this.date_style}>
          {label}
        </div>
        {viewValue}
      </div>
    )
  }  
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Week extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {  // <div>：カレンダーの曜日が並ぶ列
      display:"flex", 
    }
    this.dayName_style = {
      display:"flex", 
      alignItems:"center",
      minWidth:"14.285%",
      justifyContent : "center",
      fontSize : 25,
      padding : 8,
    }
    
  }
  //_____________________________________________________________________________________

  render() {
    const {breakPoint, lang } = this.props;
    // マルチ言語化（曜日、年月タイトル）

    let dayNames    = [];                         // 各言語ごとに曜日を入れる配列
    
    switch(lang){
      case "en"  : { 
        dayNames = ["Sun","Mon","Tue","Wed","Thu","Fri","Sat"];
        break;
      }
      case "ja" : default : { 
        dayNames = ["日","月","火","水","木","金","土"];  
        break;
      }
    }
    // 曜日を作成

    let day_arr      = [];             // 曜日と曜日No【０～６ : 日～土】と合わせるための配列
    let dayNameCount  = null;           // 曜日と曜日No【０～６ : 日～土】と合わせて配列に入れていくためのカウント変数

    if ( breakPoint === 6 ){
      dayNameCount  = 0;
    }else{
      dayNameCount  = breakPoint + 1;
    }

    for(let i = 0; i <= 6; i++){
      day_arr.push(
        <div style={this.dayName_style} key={"dayName"+i}>
          { dayNames[dayNameCount] }
        </div>
      )

      if( dayNameCount === 6 ){
        dayNameCount = 0;
      }else{
        dayNameCount ++ ;
      }
    }

    return(
      <div style={this.wrap_style}>
        {day_arr}
      </div>
    );
  }
}
//////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

class Header extends React.Component{
  constructor(props){
    super(props);
    this.wrap_style = {  // <div>：カレンダーの年月タイトル
      display:"flex", 
      justifyContent:"space-between", 
      alignItems:"center",
    }
    this.changeMonth_style = {
      border:"none",
      backgroundColor:"transparent",
      marginLeft:8,
      fontSize:25,
      cursor:"pointer",
    }
    this.title_style = {
      fontSize:25,
      whiteSpace: "nowrap",
    }
  }
  // ________________________________________________________________________________________________________________

  render(){
    const { 
      year, 
      month, 
      lang,
      onClickPreviousMonth,
      onClickNextMonth,
      updateEvent,
    } = this.props;
    let title  = null;                           // 年月タイトルを表示するための変数
    let monthsLang  = [];

    switch(lang){

      case "en" : {
        monthsLang = ["Jan.","Feb.","Mar.","Apr.","May","June","July","Aug.","Sept.","Oct.","Nov.","Dec."]
        title = monthsLang[month - 1] + year;           // 例：Aug.2019
        break;
      }
      case "ja" : default : {
        monthsLang  = ["1月","2月","3月","4月","5月","6月","7月","8月","9月","10月","11月","12月"];
        title = year + "年" + monthsLang[month - 1] ;    // 例：2019年8月
        break;
      }
                    
    }
    // ________________________________________________________________________________________________________________

    return(
      <div style={this.wrap_style}>
        <div style={this.title_style}>
          {title}    {/* 年月タイトル */}
        </div>
        <div>
        <button style={this.changeMonth_style} onClick={onClickPreviousMonth}>  ≪ </button>
        <button style={this.changeMonth_style} onClick={onClickNextMonth}> ≫ </button>
        {
          this.props.readOnly !== true &&
          <Sky.Btn onClick={()=>updateEvent()} className='mrow2-button orange' label="更新" />
        }
      </div>
      </div>
    );
  }
}