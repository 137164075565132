import React, { Fragment,Component } from 'react';
import {Route,Switch,Prompt} from 'react-router-dom';
import Blank from '../../component/Blank';
import { connect } from 'react-redux';
import { replace } from 'connected-react-router'　//Logout用

    //Menu機能をImportするFunction（非同期）
    const lazyLoader = (importComponent) => (
        class AsyncComponent extends Component {
          state = { C: null }
          async componentDidMount () {
            const { default: C } = await importComponent();
            this.setState({ C });
           }
           //アラート退避
           componentWillUnmount = () => {
             this.setState = (state,callback)=>{
               return;
             };
         }
           render () {
           const { C } = this.state;
           return C ? <C {...this.props} /> : null;
         }
       }
     );
     
     
class RouterContainer extends Component {
  constructor (props) {
    super(props);
    this.props.dispatch(replace('/'))
  }
  
  render(){
    return (
      <Fragment>
        <Prompt 
          when={ this.props.location.pathname !== '/'}
            message={ location => {
              if(location.state && location.state.showPrompt === false){
                return true;
              } else{
                return "現在の画面から離れてもよろしいですか?"
              }
             }
            } />
        <Switch>
            <Route exact path="/" component={Blank} />
            <Route path='/ean/ean01/EAN01'  component={lazyLoader(() => import('../ean/ean01/index'))} />
            <Route path='/emm/emm01/EMM01'  component={lazyLoader(() => import('../emm/emm01/index'))} />
            <Route path='/emm/emm02/EMM02'  component={lazyLoader(() => import('../emm/emm02/index'))} />
            <Route path='/emm/emm03/EMM03'  component={lazyLoader(() => import('../emm/emm03/index'))} />
            <Route path='/emm/emm04/EMM04'  component={lazyLoader(() => import('../emm/emm04/index'))} />
            <Route path='/emm/emm05/EMM05'  component={lazyLoader(() => import('../emm/emm05/index'))} />
            <Route path='/emm/emm06/EMM06'  component={lazyLoader(() => import('../emm/emm06/index'))} />
            <Route path='/emm/emm07/EMM07'  component={lazyLoader(() => import('../emm/emm07/index'))} />
            <Route path='/emm/emm09/EMM09'  component={lazyLoader(() => import('../emm/emm09/index'))} />
            <Route path='/eac/eac01/EAC01'  component={lazyLoader(() => import('../eac/eac01/index'))} />
            <Route path='/eor/eor01/EOR01'  component={lazyLoader(() => import('../eor/eor01/index'))} />
            <Route path='/eps/eps01/EPS01'  component={lazyLoader(() => import('../eps/eps01/index'))} />
            <Route path='/eps/eps03/EPS03'  component={lazyLoader(() => import('../eps/eps03/index'))} />
            <Route path='/eda/eda01/EDA01'  component={lazyLoader(() => import('../eda/eda01/index'))} />
            <Route path='/eda/eda02/EDA02'  component={lazyLoader(() => import('../eda/eda02/index'))} />
            <Route path='/esa/esa01/ESA01'  component={lazyLoader(() => import('../esa/esa01/index'))} />
            <Route path='/esa/esa02/ESA02'  component={lazyLoader(() => import('../esa/esa02/index'))} />
            <Route path='/esa/esa03/ESA03'  component={lazyLoader(() => import('../esa/esa03/index'))} />
       </Switch>
       </Fragment>
    )
}
}
const mapStateToProps = (state,ownPropS) =>{
    return {
      //stateの範囲を縮めるとRender回数は減ります
      ...state.router,
    }
  }
  export default connect(mapStateToProps)(RouterContainer);